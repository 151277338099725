import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { ChevronRightIcon, TrashIcon } from '@heroicons/react/outline'

import { transition } from '../../../../../../styles'

import usePPA from '../../../../../../hooks/use-ppa'

import Table from '../../../../../../components/atoms/table'
import BidPill from '../../../../components/bid-pill'
import Button, {
  Props as ButtonProps,
} from '../../../../../../components/atoms/button'
import { feedbackMessage } from '../../../../../../components/atoms/feedback'

import {
  SupplierTenderBid,
  SupplierPowerPriceName,
  EmbeddedBenefitName,
  SubsidyName,
  ChargeName,
} from '../../../../../../types'
import { mappedMatchPercentage } from '../../../../../../utils/data'

export const priceTypeOptions: Record<
  'MATCH_AND_UNMATCHED' | 'FIXED' | 'PASSTHROUGH',
  string
> = {
  PASSTHROUGH: 'passthrough',
  FIXED: 'fixed',
  MATCH_AND_UNMATCHED: 'matchUnmatched',
}

const defaultStyles = {
  eachRow:
    'min-h-[45px] min-w-[235px] border-b border-b-ppa/grayBorder px-1.5 flex text-ppa/tableRowText',
  titleRow: 'bg-ppa/grayBackground',
}

const mappedPriceType = {
  FIXED: '£/MWh',
  FLEXIBLE: '%',
}

const mappedGreenCertificate = {
  FIXED: '£/MWh',
  FLEXIBLE: '%',
}

const mappedBenefit = {
  FIXED: '£/MWh',
  FLEXIBLE: '%',
}

const mappedCharge = {
  MWH: '£/MWh',
  MONTHLY: '£/mo',
  DAILY: '£/day',
}

export interface ButtonList {
  props: ButtonProps
  text: string
  onClick: () => void
}

export interface BidTableProps {
  bid: SupplierTenderBid
  refetchBidList: () => void
  buttons?: ButtonList[]
}

const BidTable: React.FC<BidTableProps> = ({
  bid,
  buttons,
  refetchBidList,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.sleevedTenders.details.bidTable',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const [isLoading, setIsLoading] = useState(false)

  const [collapseRows, setCollapseRows] = useState({
    greenCertificates: false,
    benefits: false,
    charges: false,
  })

  const handleDeleteBid = async () => {
    if (isLoading) return
    setIsLoading(true)

    try {
      const { error } = await fetchData({
        method: 'DELETE',
        url: `/core/private/supplier-tender-bid/supplier/${bid.id}`,
      })

      if (error) throw error

      refetchBidList()

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('handleDeleteBid.success.description'),
        },
        'success',
      )
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'supplierTenderBid',
        AUTHORIZATION_ERROR: ({ message }) => {
          if (
            message?.includes(
              'You do not have permission to change the Bid to this status',
            )
          ) {
            return {
              title: tUtils(
                'throwFeedbackError.errorCodes.supplierTenderBid.ERR_NOT_AUTHORIZED.title',
              ),
              description: tUtils(
                'throwFeedbackError.errorCodes.supplierTenderBid.ERR_NOT_AUTHORIZED.reason.STATUS_PERMISSION',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    } finally {
      setIsLoading(false)
    }
  }

  const parsedBid = useMemo(() => {
    if (isLoading) return undefined

    const supplierBidPowerPrices = bid.supplierTenderBidPowerPrices.map(
      (powerPrice) => ({
        id: powerPrice.id,
        value: `${Number(powerPrice.value).toFixed(2)} ${
          mappedPriceType[
            (powerPrice.type as keyof typeof mappedPriceType) || 'FIXED'
          ]
        }`,
        name: powerPrice.name,
        type:
          priceTypeOptions[powerPrice.type as keyof typeof priceTypeOptions] ||
          powerPrice.type,
        supplierTenderBidId: powerPrice.supplierTenderBidId,
      }),
    )

    const benefits: { [key in EmbeddedBenefitName]: string } = {
      GDUOS: '',
      BSUOS: '',
      AAHEDC: '',
      DLOSS: '',
      TLOSS: '',
      OTHER: '',
      ELEXON: '',
      RCRC: '',
    }
    bid.supplierTenderBidBenefits.forEach((benefit) => {
      benefits[benefit.name] = (benefit.value as any)
        ? `${Number(benefit.value).toFixed(2)} ${
            mappedBenefit[
              (benefit.type as keyof typeof mappedBenefit) || 'FIXED'
            ]
          }`
        : ''
    })

    const greenCertificates: { [key in SubsidyName]: string } = {
      REGO: '',
      ROC: '',
      ROC_BUYOUT: '',
      ROC_RECYCLE: '',
      FIT: '',
      FIT_EXPORT: '',
    }
    bid.supplierTenderBidGreenCertificates.forEach((subsidy) => {
      greenCertificates[subsidy.name] = (subsidy.value as number)
        ? `${Number(subsidy.value).toFixed(2)} ${
            mappedGreenCertificate[
              (subsidy.type as keyof typeof mappedGreenCertificate) || 'FIXED'
            ]
          }`
        : ''
    })

    const charges: { [key in ChargeName]: string } = {
      BROKER_FEE: '',
      DA_DC: '',
      MANAGEMENT_FEE: '',
      THIRD_PARTY: '',
      OTHER: '',
    }
    bid.supplierTenderBidCharges.forEach((charge) => {
      charges[charge.name] = (charge.value as number)
        ? `${Number(charge.value).toFixed(2)} ${
            mappedCharge[(charge.type as keyof typeof mappedCharge) || 'MWH']
          }`
        : ''
    })

    const result = {
      ...bid,
      benefits,
      greenCertificates,
      charges,
      supplierBidPowerPrices,
    }

    return result
  }, [isLoading, bid])

  return (
    <div className="min-w-[400px] max-w-[520px]">
      <Table
        data={[bid]}
        headers={[t('headers.status'), '']}
        rowKeys={[
          {
            keyName: 'bid.status',
            renderCustomEl: (item: SupplierTenderBid) => (
              <BidPill status={item.status} key={`status-${item.id}`} />
            ),
          },
          {
            keyName: 'actions',
            renderCustomEl: (item) => (
              <div
                className="ml-auto flex items-center justify-end gap-x-3"
                key={`actions-${item.id}`}
              >
                {buttons?.map((button) => (
                  <Button
                    {...button.props}
                    key={button.text}
                    onClick={() => button.onClick()}
                  >
                    {button.text}
                  </Button>
                ))}
              </div>
            ),
          },
        ]}
        readOnly
      />

      <div className="flex flex-col w-full">
        {!isLoading && parsedBid && (
          <div className="flex flex-col gap-y-5">
            <section className="flex flex-col gap-y-5">
              <article className="flex pl-5">
                <div className="flex flex-col">
                  <div
                    className={classNames(
                      'text-ppa/tableRowText text-xs font-normal',
                      'w-full pb-2 ml-2 -mt-2',
                    )}
                  >
                    <span key={`powerprice-title-${parsedBid.id}`}>
                      {t('powerPrice.title')}
                    </span>
                  </div>
                  <div
                    className={classNames(
                      'text-ppa/tableRowText text-xs font-light',
                      'flex flex-col justify-center items-start gap-y-1.5',
                      'min-w-[235px] ml-2 pb-1.5',
                    )}
                  >
                    {parsedBid.supplierBidPowerPrices.map((powerPrice) => {
                      return (
                        <span key={`priceType-${powerPrice.name}`}>
                          {tUtils(`bids.priceTypeSleeved.${powerPrice.name}`)}
                        </span>
                      )
                    })}
                  </div>

                  {bid.contractTypeName === 'MATCH_AND_UNMATCHED' && (
                    <>
                      <div className="border-b border-b-ppa/grayBorder w-full" />
                      <div
                        className={classNames(
                          'text-ppa/tableRowText text-xs font-normal',
                          'w-full py-3 ml-2',
                        )}
                      >
                        <span key={parsedBid.forecastMatch}>
                          {t('forecastMatchPercentage.title')}
                        </span>
                      </div>
                    </>
                  )}

                  <div
                    className={classNames(
                      'text-ppa/tableRowText text-xs font-normal py-2',
                      'flex items-center justify-start gap-y-1',
                      'border-t border-t-ppa/grayBorder w-full',
                    )}
                  >
                    <ChevronRightIcon
                      onClick={() =>
                        setCollapseRows({
                          ...collapseRows,
                          benefits: !collapseRows.benefits,
                        })
                      }
                      className={classNames(
                        transition,
                        'w-4 h-4 text-ppa/tableRowText cursor-pointer hover:brightness-75',
                        collapseRows.benefits && 'rotate-90',
                      )}
                    />
                    <span key={`benefitsTitle-${parsedBid.benefits.ELEXON}`}>
                      {t('embeddedBenefits.title')}
                    </span>
                  </div>
                  <div
                    className={classNames(
                      transition,
                      'text-ppa/tableRowText text-xs font-light',
                      'flex flex-col justify-center items-start ml-8',
                      'gap-x-1 min-w-[235px]',
                      !collapseRows.benefits &&
                        'max-h-[0px] invisible opacity-0',
                    )}
                  >
                    <span key={`gduos-${parsedBid.benefits.GDUOS}`}>GDUoS</span>
                    <span key={`dloss-${parsedBid.benefits.DLOSS}`}>
                      D-loss
                    </span>
                    <span key={`tloss}-${parsedBid.benefits.TLOSS}`}>
                      T-loss
                    </span>
                    <span key={`rcrc-${parsedBid.benefits.RCRC}`}>RCRC</span>
                    <span key={`elexon-${parsedBid.benefits.ELEXON}`}>
                      ELEXON
                    </span>
                  </div>
                  <div className="border-b border-b-ppa/grayBorder w-full" />
                  <div
                    className={classNames(
                      'text-ppa/tableRowText text-xs font-normal py-2',
                      'flex items-center justify-start gap-y-1',
                    )}
                  >
                    <ChevronRightIcon
                      onClick={() =>
                        setCollapseRows({
                          ...collapseRows,
                          greenCertificates: !collapseRows.greenCertificates,
                        })
                      }
                      className={classNames(
                        transition,
                        'w-4 h-4 text-ppa/tableRowText cursor-pointer hover:brightness-75',
                        collapseRows.greenCertificates && 'rotate-90',
                      )}
                    />
                    <span
                      key={`greenCertificates-title-${parsedBid.greenCertificates.REGO}`}
                    >
                      {t('greenCertificates.title')}
                    </span>
                  </div>
                  <div
                    className={classNames(
                      transition,
                      'text-ppa/tableRowText text-xs font-light',
                      'flex flex-col justify-center items-start ml-8',
                      'gap-x-1 min-w-[235px]',
                      !collapseRows.greenCertificates &&
                        'max-h-[0px] invisible opacity-0',
                    )}
                  >
                    <span
                      key={`buyout-${parsedBid.greenCertificates.ROC_BUYOUT}`}
                    >
                      ROC ({t('greenCertificates.buyout')})
                    </span>
                    <span
                      key={`recycle-${parsedBid.greenCertificates.ROC_RECYCLE}`}
                    >
                      ROC ({t('greenCertificates.recycle')})
                    </span>
                    <span key={`rego-${parsedBid.greenCertificates.REGO}`}>
                      REGO
                    </span>
                  </div>
                  <div className="border-b border-b-ppa/grayBorder w-full" />
                  <div
                    className={classNames(
                      'text-ppa/tableRowText text-xs font-normal py-2',
                      'flex items-center justify-start gap-y-1',
                    )}
                  >
                    <ChevronRightIcon
                      onClick={() =>
                        setCollapseRows({
                          ...collapseRows,
                          charges: !collapseRows.charges,
                        })
                      }
                      className={classNames(
                        transition,
                        'w-4 h-4 text-ppa/tableRowText cursor-pointer hover:brightness-75',
                        collapseRows.charges && 'rotate-90',
                      )}
                    />
                    <span key={`charges-title-${parsedBid.charges.BROKER_FEE}`}>
                      {t('charges.title')}
                    </span>
                  </div>
                  <div
                    className={classNames(
                      transition,
                      'text-ppa/tableRowText text-xs font-light',
                      'flex flex-col justify-center items-start ml-8',
                      'gap-x-1 min-w-[235px]',
                      !collapseRows.charges &&
                        'max-h-[0px] invisible opacity-0',
                    )}
                  >
                    <span key={`daDc-${parsedBid.charges.DA_DC}`}>
                      {t('charges.daDc')}
                    </span>
                    <span key={`thirdParty-${parsedBid.charges.THIRD_PARTY}`}>
                      {t('charges.thirdParty')}
                    </span>
                    <span
                      key={`managementFee-${parsedBid.charges.MANAGEMENT_FEE}`}
                    >
                      {t('charges.managementFee')}
                    </span>
                    <span key={`other-${parsedBid.charges.OTHER}`}>
                      {t('charges.other')}
                    </span>
                    <span
                      className="font-medium"
                      key={`brokerFee-${parsedBid.charges.BROKER_FEE}`}
                    >
                      {t('charges.brokerFee')}
                    </span>
                  </div>
                  <div className="border-b border-b-ppa/grayBorder w-full" />
                  <div
                    className={classNames(
                      defaultStyles.eachRow,
                      'font-semibold text-sm',
                      'items-center justify-start w-full',
                    )}
                    key={bid.id}
                  />
                </div>
                <div className={classNames('flex flex-col')}>
                  <div
                    className={classNames(
                      'text-ppa/grayTextTable text-xs font-light py-2 text-transparent',
                      'flex items-center justify-start gap-y-1 select-none pointer-events-none',
                      'w-full',
                    )}
                  >
                    <span key={parsedBid.expiresAt}>Power price types</span>
                  </div>

                  <div
                    className={classNames(
                      'text-ppa/tableRowText text-xs font-light',
                      'flex flex-col items-end justify-center gap-y-1.5 -mt-4',
                      'px-1.5 pb-1.5',
                      bid.contractTypeName === 'MATCH_AND_UNMATCHED' &&
                        'border-b border-b-ppa/grayBorder min-w-[235px]',
                    )}
                  >
                    {parsedBid.supplierBidPowerPrices.map((powerPrice) => {
                      return (
                        <span key={powerPrice.value}>{powerPrice.value}</span>
                      )
                    })}
                  </div>

                  {bid.contractTypeName === 'MATCH_AND_UNMATCHED' && (
                    <div
                      className={classNames(
                        'text-ppa/tableRowText text-xs font-medium',
                        'flex flex-col items-end justify-center',
                        'min-w-[235px] px-1.5 py-3',
                      )}
                    >
                      <span
                        key={`${bid.forecastMatch}-${bid.contractTypeName}`}
                      >
                        {bid.forecastMatch
                          ? mappedMatchPercentage[
                              bid.forecastMatch as keyof typeof mappedMatchPercentage
                            ]
                          : '-'}
                      </span>
                    </div>
                  )}

                  <div
                    className={classNames(
                      'text-ppa/grayTextTable text-xs font-light py-2 text-transparent',
                      'flex items-center justify-start gap-y-1 select-none pointer-events-none',
                      'border-t border-t-ppa/grayBorder w-full',
                    )}
                  >
                    <span>Embedded benefits</span>
                  </div>

                  <div
                    className={classNames(
                      transition,
                      'text-ppa/tableRowText text-xs font-light',
                      'flex flex-col items-end justify-center min-w-[235px] px-1.5',
                      'border-b border-b-ppa/grayBorder',
                      !collapseRows.benefits && 'max-h-[0px] text-transparent',
                    )}
                  >
                    <span>{parsedBid.benefits.GDUOS || '-'}</span>
                    <span>{parsedBid.benefits.DLOSS || '-'}</span>
                    <span>{parsedBid.benefits.TLOSS || '-'}</span>
                    <span>{parsedBid.benefits.RCRC || '-'}</span>
                    <span>{parsedBid.benefits.ELEXON || '-'}</span>
                  </div>

                  <div
                    className={classNames(
                      'text-ppa/grayTextTable text-xs font-light py-2 text-transparent',
                      'flex items-center justify-start gap-y-1 select-none pointer-events-none',
                    )}
                  >
                    <span>greenCertificates</span>
                  </div>

                  <div
                    className={classNames(
                      'text-ppa/tableRowText text-xs font-light',
                      'flex flex-col items-end justify-center min-w-[235px] px-1.5',
                      'border-b border-b-ppa/grayBorder',
                      !collapseRows.greenCertificates &&
                        'max-h-[0px] text-transparent',
                    )}
                  >
                    <span>
                      {parsedBid.greenCertificates?.ROC_BUYOUT || '-'}
                    </span>
                    <span>
                      {parsedBid.greenCertificates?.ROC_RECYCLE || '-'}
                    </span>
                    <span>{parsedBid.greenCertificates?.REGO || '-'}</span>
                  </div>

                  <div
                    className={classNames(
                      'text-ppa/grayTextTable text-xs font-light py-2 text-transparent',
                      'flex items-center justify-start gap-y-1 select-none pointer-events-none',
                    )}
                  >
                    <span>Charges</span>
                  </div>

                  <div
                    className={classNames(
                      transition,
                      'text-ppa/tableRowText text-xs font-light',
                      'flex flex-col items-end justify-center min-w-[235px] px-1.5',
                      'border-b border-b-ppa/grayBorder',
                      !collapseRows.charges && 'max-h-[0px] text-transparent',
                    )}
                  >
                    <span>{parsedBid.charges?.DA_DC || '-'}</span>
                    <span>{parsedBid.charges?.THIRD_PARTY || '-'}</span>
                    <span>{parsedBid.charges?.MANAGEMENT_FEE || '-'}</span>
                    <span>{parsedBid.charges?.OTHER || '-'}</span>
                    <span>{parsedBid.charges?.BROKER_FEE || '-'}</span>
                  </div>
                  <div
                    className={classNames(
                      defaultStyles.eachRow,
                      'font-semibold text-sm',
                      'items-center justify-start w-full',
                    )}
                  />
                </div>
              </article>
            </section>
          </div>
        )}
      </div>
    </div>
  )
}

export default BidTable
