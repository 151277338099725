import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const RouteTracker: React.FC = () => {
  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams.has('code') || searchParams.has('state')) {
      return
    }

    // do not update if the current pathname is "/home" because that's the auth0 default (and invalid) route in our app
    if (location.pathname === '/home') {
      return
    }

    const currentRoute = location.pathname + location.search
    localStorage.setItem('lastValidRoute', currentRoute)
  }, [location])

  return null
}

export default RouteTracker
