/* eslint-disable camelcase */
import { useEffect, useState, useContext, useMemo, createContext } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import { UserAuth0 } from '../../types/auth0'

import { GeneratorAppMetadata } from '../../types/user/generator'
import { SupplierAppMetadata } from '../../types/user/supplier'
import { ConsumerAppMetadata } from '../../types/user/consumer'
import {
  UserTypes,
  UserHook,
  UserGenerator,
  UserSupplier,
  UserConsumer,
} from '../../types/user'
import { Children } from '../../types'

type ActAsPayload =
  | { appMetadata: GeneratorAppMetadata }
  | { appMetadata: SupplierAppMetadata }
  | { appMetadata: ConsumerAppMetadata }
type ActAsFn = (payload: ActAsPayload) => void

type UseUserProps<UserType = UserTypes> = {
  user: UserHook<UserType>
  actAs: ActAsFn
  stopActingAs: () => void
  isReady: boolean
}

type LocalStorageActingAs =
  | UserGenerator['appMetadata']
  | UserSupplier['appMetadata']
  | UserConsumer['appMetadata']

const parseAuth0DataToHookData = ({
  app_metadata,
  user_metadata,
  ...restOfUserInfo
}: UserAuth0): UserHook => {
  if (app_metadata.userType === 'GENERATOR') {
    return {
      ...restOfUserInfo,
      userMetadata: user_metadata,
      appMetadata: app_metadata as UserHook<'GENERATOR'>['appMetadata'],
    }
  }

  if (app_metadata.userType === 'SUPPLIER') {
    return {
      ...restOfUserInfo,
      userMetadata: user_metadata,
      appMetadata: app_metadata as UserHook<'SUPPLIER'>['appMetadata'],
    }
  }

  if (app_metadata.userType === 'CONSUMER') {
    return {
      ...restOfUserInfo,
      userMetadata: user_metadata,
      appMetadata: app_metadata as UserHook<'CONSUMER'>['appMetadata'],
    }
  }

  if (app_metadata.userType === 'ADMIN') {
    const actingAs = localStorage.getItem('@PPAYA:ActingAs')

    if (actingAs) {
      const parsedActingAs = JSON.parse(actingAs) as LocalStorageActingAs

      if (parsedActingAs?.userType === 'GENERATOR' && parsedActingAs?.id) {
        return {
          ...restOfUserInfo,
          userMetadata: user_metadata,
          appMetadata: parsedActingAs as UserHook<'GENERATOR'>['appMetadata'],
          adminActing: app_metadata,
        }
      }

      if (parsedActingAs?.userType === 'SUPPLIER' && parsedActingAs?.id) {
        return {
          ...restOfUserInfo,
          userMetadata: user_metadata,
          appMetadata: parsedActingAs as UserHook<'SUPPLIER'>['appMetadata'],
          adminActing: app_metadata,
        }
      }

      if (parsedActingAs?.userType === 'CONSUMER' && parsedActingAs?.id) {
        return {
          ...restOfUserInfo,
          userMetadata: user_metadata,
          appMetadata: parsedActingAs as UserHook<'CONSUMER'>['appMetadata'],
          adminActing: app_metadata,
        }
      }
    }

    return {
      ...restOfUserInfo,
      userMetadata: user_metadata,
      appMetadata: app_metadata,
    }
  }

  throw new Error('Error on parsing Auth0')
}

const UserContext = createContext<UseUserProps<UserTypes>>(
  {} as UseUserProps<UserTypes>,
)

export const UserProvider: React.FC<Children> = ({ children }) => {
  const {
    isAuthenticated,
    user: userAuth0,
    getAccessTokenSilently,
  } = useAuth0<UserAuth0>()

  const [isReady, setIsReady] = useState(false)
  const [user, setUser] = useState<UserHook | undefined>()

  const actAs: ActAsFn = (payload) => {
    if (
      !user ||
      !userAuth0?.app_metadata ||
      !userAuth0?.app_metadata?.userType ||
      !userAuth0?.app_metadata?.status
    ) {
      return
    }

    if (
      userAuth0?.app_metadata.userType &&
      userAuth0.app_metadata.userType === 'ADMIN'
    ) {
      localStorage.setItem(
        '@PPAYA:ActingAs',
        JSON.stringify({ ...payload.appMetadata }),
      )
      setUser({
        ...user,
        appMetadata: { ...payload.appMetadata },
        adminActing: { ...userAuth0.app_metadata },
      } as UserHook)
    }
  }

  const stopActingAs = () => {
    if (user?.adminActing) {
      localStorage.removeItem('@PPAYA:ActingAs')
      setUser({
        ...user,
        appMetadata: user.adminActing,
        adminActing: undefined,
      })
    }
  }

  useEffect(() => {
    try {
      if (isAuthenticated && userAuth0) {
        const userType = userAuth0?.app_metadata?.userType
        const { app_metadata, user_metadata, ...userInformation } = userAuth0
        if (
          userType &&
          ['ADMIN', 'GENERATOR', 'SUPPLIER', 'CONSUMER'].includes(userType)
        ) {
          if (
            !app_metadata?.id ||
            !app_metadata?.userId ||
            !app_metadata?.status ||
            app_metadata?.status === 'DELETED'
          ) {
            return
          }

          const userState = parseAuth0DataToHookData({
            app_metadata,
            user_metadata,
            ...userInformation,
          })

          setUser(userState as UserHook)
          setIsReady(true)
        }
      }
    } catch (error) {
      console.error('Error on parsing the user hook.', error)
    }
  }, [isAuthenticated, userAuth0])

  useEffect(() => {
    const refreshToken = async () => {
      try {
        await getAccessTokenSilently({ cacheMode: 'off' })
      } catch (error) {
        console.error('Error refreshing token:', error)
      }
    }

    // Refresh token every 2 hours (7200 seconds)
    const interval = setInterval(refreshToken, 2 * 60 * 60 * 1000)

    return () => clearInterval(interval)
  }, [getAccessTokenSilently])

  const providerValues = useMemo(() => {
    return { user: user as UserHook, actAs, stopActingAs, isReady }
  }, [user, actAs, stopActingAs, isReady])

  return (
    <UserContext.Provider value={providerValues}>
      {children}
    </UserContext.Provider>
  )
}

export default function useUser<
  UserType extends UserTypes = UserTypes,
>(): UseUserProps<UserType> {
  const context = useContext(UserContext)

  if (!context) {
    throw new Error('useUser must be used within an UserProvider')
  }

  return context
}
