/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useCallback, useState, useRef, useEffect } from 'react'
import { useNavigate, Outlet, useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import {
  LogoutIcon,
  AdjustmentsIcon,
  UserIcon,
  SwitchHorizontalIcon,
  BellIcon,
} from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Toaster } from 'react-hot-toast'

import { ReactComponent as Xicon } from './assets/Xicon-navbar.svg'
import { ReactComponent as ProfileIcon } from './assets/Profile-Icon.svg'
import { ReactComponent as MenuIcon } from './assets/Menu-Icon.svg'
import { transition } from '../../styles'

import useClickOutside from '../../hooks/use-click-outside'
import useUser from '../../hooks/use-user'
import useNotification from '../../hooks/use-read-notifications'

import { isFeatureEnabled } from '../../utils/features'

import CompanyLogo from '../../components/atoms/company-logo'
import AiAssistant from '../../components/organisms/ai-assistant'

import { privateLinks } from './routes'
import NavItem from './navigation-item'

import AllSetModal from './all-set-modal'

const NavBar: React.FC = () => {
  const { t } = useTranslation('private/index')

  const location = useLocation()

  const { logout } = useAuth0()
  const { user } = useUser()

  const navigate = useNavigate()

  const { unreadNotifications, handleNotificationsChange } = useNotification()

  const navBarRef = useRef(null)

  const [isNavBarOpen, setIsNavBarOpen] = useState(false)

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(null)

  const handleToggleSubMenu = (label: any) => {
    setIsSubMenuOpen(isSubMenuOpen === label ? null : label)
  }

  useClickOutside({
    ref: navBarRef,
    onClickOutside: () => setIsNavBarOpen(false),
  })

  const toggleMenu = () => {
    setIsNavBarOpen(!isNavBarOpen)
  }

  const handleLogout = useCallback(async () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
        federated: false,
      },
    })
  }, [logout])

  useEffect(() => {
    if (unreadNotifications) return

    handleNotificationsChange()
  }, [])

  return (
    <section
      className={classNames(
        'flex flex-col',
        isNavBarOpen ? 'h-full w-full bg-ppa/sidebarBackground' : 'bg-white',
        'sm:bg-ppa/sidebarBackground sm:min-w-[225px] sm:max-w-[325px] sm:px-3 sm:py-4 sm:h-full sm:overflow-y-auto',
      )}
    >
      <header className="flex justify-center">
        <button
          className="w-11"
          type="button"
          onClick={toggleMenu}
          ref={navBarRef}
        >
          {isNavBarOpen ? (
            <Xicon
              className={classNames(
                transition,
                'absolute top-5 left-6 cursor-pointer sm:hidden',
              )}
            />
          ) : (
            <MenuIcon
              className={classNames(
                transition,
                'absolute top-5 left-6 cursor-pointer sm:hidden',
              )}
            />
          )}
        </button>
        <button
          type="button"
          className="hidden sm:relative sm:flex sm:mt-5 sm:mb-20 sm:mr-10 sm:w-full sm:h-auto sm:max-w-[140px] sm:cursor-pointer"
          onClick={() => {
            navigate('/')
            setIsNavBarOpen(false)
          }}
        >
          <CompanyLogo />
        </button>
        <button
          type="button"
          className="absolute top-0 self-center cursor-pointer sm:hidden"
          onClick={() => {
            navigate('/')
            setIsNavBarOpen(false)
          }}
        >
          <CompanyLogo variant="mobile" />
        </button>
        <div>
          <ProfileIcon
            className={classNames(
              transition,
              'absolute top-3.5 right-6 sm:hidden',
            )}
          />
        </div>
      </header>
      {user.appMetadata.userType && privateLinks[user.appMetadata.userType] && (
        <nav
          className={classNames(
            isNavBarOpen ? 'flex flex-col gap-y-2.5 mt-24' : 'hidden',
            'sm:flex sm:flex-col sm:gap-y-2.5 sm:mb-8 sm:-mt-8',
          )}
          ref={navBarRef}
          onClick={() => setIsNavBarOpen(false)}
        >
          {privateLinks[user.appMetadata.userType].map(
            ({ to, icon, label, subMenu }) => (
              <NavItem
                key={label}
                to={to}
                icon={icon}
                label={label}
                subMenu={subMenu}
                currentPathname={location.pathname}
                isSubMenuOpen={isSubMenuOpen === label}
                onSubMenuToggle={handleToggleSubMenu}
              />
            ),
          )}
        </nav>
      )}
      <div className="flex flex-col mt-20 sm:mt-auto mx-2">
        <div className="flex flex-col sm:gap-y-5 sm:ml-2 sm:mb-4">
          {isFeatureEnabled('notificationsPage') && (
            <button
              type="button"
              onClick={() => {
                navigate('/notifications')
                setIsNavBarOpen(false)
              }}
              className={classNames(
                transition,
                isNavBarOpen
                  ? 'flex items-center justify-center hover:brightness-110'
                  : 'hidden',
                'sm:flex sm:items-center sm:justify-start sm:hover:brightness-110',
              )}
            >
              <span className="flex">
                {unreadNotifications && (
                  <div
                    className={classNames(
                      'hidden sm:flex sm:h-1.5 sm:w-1.5 sm:rounded-full sm:mr-[-7px] sm:mt-[2px] sm:z-50',
                      transition,
                      unreadNotifications
                        ? 'bg-ppa/primaryActive'
                        : 'bg-transparent',
                    )}
                  />
                )}
                <BellIcon
                  className={classNames(
                    'hidden sm:flex sm:mr-2.5 sm:w-4 sm:h-4 stroke-ppa/sidebarButtonIcon',
                    location.pathname.includes('notifications') &&
                      'stroke-ppa/sidebarButtonIconActive',
                  )}
                />
              </span>

              <span
                className={classNames(
                  'text-ppa/sidebarButtonText text-base font-normal sm:text-sm',
                  location.pathname.includes('notifications') &&
                    'text-ppa/sidebarButtonTextActive',
                )}
              >
                {t('sideBar.notifications')}
              </span>
            </button>
          )}

          <button
            type="button"
            onClick={() => {
              navigate('/settings')
              setIsNavBarOpen(false)
            }}
            className={classNames(
              transition,
              isNavBarOpen
                ? 'flex items-center justify-center hover:brightness-110'
                : 'hidden',
              'sm:flex sm:items-center sm:justify-start sm:hover:brightness-110',
            )}
          >
            <AdjustmentsIcon
              className={classNames(
                'hidden sm:flex sm:mr-2.5 sm:w-4 sm:h-4 stroke-ppa/sidebarButtonIcon sm:rotate-90',
                location.pathname.includes('settings') &&
                  'stroke-ppa/sidebarButtonIconActive',
              )}
            />
            <span
              className={classNames(
                'text-ppa/sidebarButtonText text-base font-normal sm:text-sm',
                location.pathname.includes('settings') &&
                  'text-ppa/sidebarButtonTextActive',
              )}
            >
              {t('sideBar.settings')}
            </span>
          </button>
          <button
            type="button"
            onClick={handleLogout}
            className={classNames(
              transition,
              isNavBarOpen
                ? 'flex items-center justify-center hover:brightness-110'
                : 'hidden',
              'sm:flex sm:items-center sm:justify-start sm:hover:brightness-110',
            )}
          >
            <LogoutIcon className="hidden sm:flex sm:mr-3 sm:w-4 sm:h-4 sm:stroke-ppa/sidebarButtonText" />
            <span className="text-ppa/sidebarButtonText text-base font-normal pt-5 sm:text-sm sm:pt-0">
              {t('sideBar.signOut')}
            </span>
          </button>
        </div>

        <div className="sm:flex sm:items-center sm:justify-start sm:gap-x-2 sm:my-2 hidden">
          <div className="bg-ppa/sidebarProfileBackground rounded-full w-[40px] h-[40px] flex items-center justify-center">
            <UserIcon className="w-7 h-7 stroke-ppa/sidebarProfileIcon m-auto" />
          </div>
          <div className="flex flex-col gap-y-1">
            <strong className="text-ppa/sidebarProfileName text-xs font-medium truncate w-[135px]">
              {user?.name || ''}
            </strong>
            <span className="text-ppa/sidebarProfileEmail text-xs font-medium truncate w-[135px]">
              {user?.email || ''}
            </span>
          </div>
        </div>
      </div>
    </section>
  )
}

const Main: React.FC = () => {
  const navigate = useNavigate()

  const { user, stopActingAs } = useUser()

  return (
    <main
      className={classNames(
        `relative w-full lg:max-w-[calc(100vw-225px)] h-full`,
        'px-8 pt-10 pb-24 bg-white overflow-y-auto',
        'pt-10',
        user?.adminActing ? 'pt-[calc(2.5rem+1.25rem+1.5rem)]' : 'pt-10',
      )}
    >
      {user?.adminActing && (
        <article
          className={classNames(
            transition,
            'fade-in flex items-center justify-between py-3 px-6',
            'absolute left-0 top-0 w-full ',
            'bg-ppa/primary',
          )}
        >
          <span className="font-light text-sm text-white">
            Acting as&nbsp;
            <strong className="font-bold">{user.appMetadata.name}</strong>
          </span>
          <div>
            <button
              className={classNames(
                transition,
                'flex items-center gap-x-2',
                'bg-transparent text-sm text-white underline cursor-pointer hover:brightness-75',
              )}
              type="button"
              onClick={() => {
                stopActingAs()
                switch (user.appMetadata.userType) {
                  case 'GENERATOR':
                    navigate('/generators')
                    break
                  case 'SUPPLIER':
                    navigate('/suppliers')
                    break
                  case 'CONSUMER':
                    navigate('/consumers')
                    break
                  default:
                    navigate('/')
                }
              }}
            >
              <SwitchHorizontalIcon className="w-5 h-5" />
              Switch account
            </button>
          </div>
        </article>
      )}
      <Outlet />
    </main>
  )
}

const Private: React.FC = () => {
  return (
    <section className="w-screen h-screen sm:flex">
      <NavBar />
      <Main />
      {isFeatureEnabled('aiAssistant') && <AiAssistant />}
      <AllSetModal />
      <Toaster />
    </section>
  )
}

export default Private
