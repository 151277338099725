/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react'

import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'

import { XIcon } from '@heroicons/react/outline'

import { transition, transitionAll } from '../../../styles'

import { ReactComponent as ChatIcon } from './assets/chat-icon.svg'
import { ReactComponent as MaximizeIcon } from './assets/maximize-icon.svg'
import { ReactComponent as AssistantIcon } from './assets/assistant-icon.svg'
import { ReactComponent as SendIconActive } from './assets/send-icon-active.svg'
import { ReactComponent as SendIconInactive } from './assets/send-icon-inactive.svg'

interface Message {
  id: string
  sender: 'user' | 'assistant'
  text: string
}

interface ChatProps {
  messages: Message[]
  onSend: (message: string) => void
  loading: boolean
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Chat: React.FC<ChatProps> = ({
  messages,
  onSend,
  loading,
  isOpen,
  setIsOpen,
}) => {
  const [isOpeningChat, setIsOpeningChat] = useState(false)
  const [isMaximized, setIsMaximized] = useState(false)
  const [isClosing, setIsClosing] = useState(false)

  const [userInput, setUserInput] = useState('')
  const [isInputFocused, setIsInputFocused] = useState(false)

  const textRef = useRef<HTMLTextAreaElement>(null)
  const messagesContainerRef = useRef<HTMLDivElement>(null)

  const SendIcon = isInputFocused ? SendIconActive : SendIconInactive

  const handleSendMessage = () => {
    if (!userInput.trim()) return
    onSend(userInput.trim())
    setUserInput('')
  }

  const handleClose = () => {
    setIsClosing(true)
    setTimeout(() => {
      setIsClosing(false)
      setIsOpen(false)
    }, 450)
  }

  useEffect(() => {
    if (messagesContainerRef.current) {
      const lastMessage = messagesContainerRef.current.lastElementChild
      if (lastMessage) {
        setTimeout(() => {
          lastMessage.scrollIntoView({ behavior: 'smooth' })
        }, 400)
      }
    }
  }, [messages, isOpen])

  return (
    <>
      <div
        className={classNames(
          transition,
          'fixed bottom-7 right-7 z-[9999]',
          'cursor-pointer',
        )}
        onClick={() => {
          setIsOpeningChat(true)
          setIsOpen(true)
        }}
        role="button"
        tabIndex={0}
      >
        <ChatIcon
          className={classNames(
            'w-[70px] h-[70px] duration-500',
            isOpen ? 'opacity-20' : 'opacity-100',
          )}
        />
      </div>

      {isOpen && (
        <div
          className={classNames(
            transitionAll,
            'h-auto',
            'flex flex-col',
            'fixed right-6 bottom-8 z-[9999]',
            'rounded-lg bg-white shadow-lg',
            isOpeningChat && 'animate-fadeIn',
            isClosing && 'animate-fadeOut',
            isMaximized
              ? 'top-6 sm:top-8 left-6 md:left-[31%] lg:left-[21%] xl:left-[17%] 4xl:left-[13%] 5xl:left-[10%] scale-200 duration-200 ease-in-out'
              : 'top-48 sm:top-28 lg:top-24 2xl:top-36 5xl:top-52 left-[20%] sm:left-[40%] md:left-[48%] lg:left-[58%] scale-100 duration-300 ease-in-out',
          )}
        >
          <div
            className={classNames(
              'w-full h-14 md:h-16',
              'flex items-center justify-between p-5',
              'bg-ppa/warning text-white rounded-t-lg',
            )}
          >
            <span className="font-semibold text-lg md:text-xl pl-2">
              AI Assistant
            </span>
            <div className="flex items-center gap-x-3">
              <div
                onClick={() => setIsMaximized((prev) => !prev)}
                className="cursor-pointer hover:brightness-75"
                role="button"
                tabIndex={0}
              >
                <MaximizeIcon className="w-[18px] h-[18px]" />
              </div>
              <div
                onClick={handleClose}
                className="cursor-pointer hover:brightness-75"
                role="button"
                tabIndex={0}
              >
                <XIcon className="w-6 h-6" />
              </div>
            </div>
          </div>

          <div
            ref={messagesContainerRef}
            className={classNames(
              'flex-1 p-4 overflow-y-auto',
              'text-sm md:text-base',
            )}
          >
            {messages.map((msg) => (
              <div
                key={msg.id}
                className={classNames('mb-3 flex', {
                  'justify-end': msg.sender === 'user',
                  'justify-start': msg.sender === 'assistant',
                })}
              >
                {msg.sender === 'assistant' && (
                  <div className="flex items-start w-full">
                    <AssistantIcon className="w-8 h-8 shrink-0" />
                    <div className="px-3 py-2 text-ppa/sidebarButtonBackground font-lato font-medium break-words">
                      <ReactMarkdown>{msg.text}</ReactMarkdown>
                    </div>
                  </div>
                )}

                {msg.sender === 'user' && (
                  <div className="bg-ppa/aiAssistantMessageBg text-ppa/sidebarBackground font-normal rounded-md px-3 py-2 ml-20">
                    {msg.text}
                  </div>
                )}
              </div>
            ))}

            {loading && (
              <div className="flex items-center w-full">
                <AssistantIcon className="w-8 h-8 shrink-0" />
                <div className="flex items-center gap-[3px] pl-3">
                  <div className="rounded-full w-1 h-1 bg-ppa/sidebarButtonBackground animate-bounce-1" />
                  <div className="rounded-full w-1 h-1 bg-ppa/sidebarButtonBackground animate-bounce-2" />
                  <div className="rounded-full w-1 h-1 bg-ppa/sidebarButtonBackground animate-bounce-3" />
                </div>
              </div>
            )}
          </div>

          <div
            className={classNames(
              'flex items-end justify-between',
              'bg-ppa/aiAssistantMessageBg rounded-md',
              'mx-5 mb-5 p-4',
            )}
          >
            <textarea
              ref={textRef}
              rows={1}
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onFocus={() => setIsInputFocused(true)}
              onBlur={(e) => {
                if (e.relatedTarget?.id !== 'send-button') {
                  setIsInputFocused(false)
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault()
                  if (loading) {
                    return
                  }
                  handleSendMessage()
                }
              }}
              placeholder="Message our AI assistant"
              className={classNames(
                'w-full h-auto min-h-[65px] md:min-h-[100px]',
                'overflow-hidden focus:outline-none',
                'text-ppa/sidebarButtonBackground text-base font-normal',
                'placeholder:text-sm placeholder:lg:text-base',
                'bg-transparent',
              )}
            />
            <button
              type="button"
              id="send-button"
              onClick={handleSendMessage}
              disabled={loading}
              className="absolute bottom-8 right-8 hover:brightness-75 disabled:cursor-not-allowed"
            >
              <SendIcon className="w-8 h-8" />
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default Chat
