/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import VerticalTab from '../../../../components/atoms/vertical-tab'

import { isFeatureEnabled } from '../../../../utils/features'

import Notifications from './notifications'

const Settings: React.FC = () => {
  return (
    <VerticalTab
      header="Settings"
      tabs={[
        {
          label: 'Notifications',
          content: <Notifications />,
        },
        isFeatureEnabled('passwordPage') && {
          label: 'Password',
          content: <></>,
        },
      ]}
    />
  )
}

export default Settings
