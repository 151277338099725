import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'

import { auth0Config } from '../auth-config'

interface Auth0ProviderWithHistoryProps {
  children: React.ReactNode
}

const Auth0ProviderWithHistory: React.FC<Auth0ProviderWithHistoryProps> = ({
  children,
}) => {
  const navigate = useNavigate()

  const onRedirectCallback = (appState?: unknown) => {
    const myAppState = appState as { targetUrl?: string } | undefined
    let targetUrl = myAppState?.targetUrl

    // If targetUrl is '/home' (default comming from auth0), use the last valid route or fallback '/'
    if (targetUrl === '/home' || !targetUrl) {
      const storedRoute = localStorage.getItem('lastValidRoute')
      targetUrl = storedRoute || '/'
    }

    navigate(targetUrl)
  }

  return (
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory
