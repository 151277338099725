import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { ChevronLeftIcon, DownloadIcon } from '@heroicons/react/outline'

import usePPA from '../../../../../../hooks/use-ppa'
import { parseQuery } from '../../../../../../hooks/use-ppa/use-ppa-get-list'

import { capitalizeText } from '../../../../../../utils'

import { container, breakPointsContainer } from '../../../../../../styles'

import Button, {
  Props as ButtonProps,
} from '../../../../../../components/atoms/button'

import TenderInvitationPill from '../../../../components/tender-invitation-pill'

import { mappedTenderStatus, AvailableRouteStatus } from '../../../types'

import {
  TenderMeterWithInvitation,
  TenderInvitationStatus,
} from '../../../../../../types'

import CollapseList from '../../../../../../components/molecules/collapse-list'
import DownloadHhd from '../../../../../../components/organisms/latest-hhd-download'

import ConsumerTenderDetailsDefList from '../details-tender'
import SiteDetailsDefList from '../details-meter'

type File = Blob & {
  fileName: string
}

export interface ButtonList {
  props: ButtonProps
  text: string
  onClick: () => void
}

interface TenderDetailsSupplierProps {
  buttons?: ButtonList[]
  tender: TenderMeterWithInvitation
}

const ConsumerTenderDetails: React.FC<TenderDetailsSupplierProps> = ({
  buttons,
  tender: tenderInvitation,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.consumerTenders.details',
  })

  const navigate = useNavigate()

  const { fetchData, throwFeedbackError } = usePPA()

  const [isLoadingHhdFiles, setIsLoadingHhdFiles] = useState(false)
  const [hhdFiles, setHhdFiles] = useState<File[] | undefined>()
  const [isDownloadingHhdFiles, setIsDownloadingHhdFiles] = useState(false)

  const [isLoadingLoaFiles, setIsLoadingLoaFiles] = useState(false)
  const [fetchedLoaFiles, setFetchedLoaFiles] = useState<File[] | []>([])
  const [isDownloadingLoaFiles, setIsDownloadingLoaFiles] = useState(false)

  const [adminHasLoa, setAdminHasLoa] = useState<boolean>(false)

  const handleFetchHhdFiles = async () => {
    if (isLoadingHhdFiles) return
    setIsLoadingHhdFiles(true)

    try {
      const { response, error: fetchError } = await fetchData({
        method: 'GET',
        url: `/core/private/consumer-meter/supplier/hhd/list?${parseQuery({
          meterId: tenderInvitation.consumerTender.consumerMeter.id,
        })}`,
        responseType: 'json',
      })

      if (fetchError) throw fetchError

      const files = response?.data.hhdFiles || []

      const processedFiles = await Promise.all(
        files.map(async (fileObject: { file: string; fileName: string }) => {
          const base64Data = fileObject.file || ''
          const fileNameFromResponse =
            fileObject.fileName || 'defaultFileName.pdf'

          const binaryResponse = await fetch(
            `data:application/octet-stream;base64,${base64Data}`,
          )
          const binaryData = await binaryResponse.blob()

          const blob = new Blob([binaryData], {
            type: 'application/octet-stream',
          })

          return { blob, fileName: fileNameFromResponse }
        }),
      )

      setHhdFiles(processedFiles)
    } catch (err: any) {
      throwFeedbackError(err)
    } finally {
      setIsLoadingHhdFiles(false)
    }
  }

  const handleDownloadHhd = async (file: any) => {
    if (isDownloadingHhdFiles) return

    try {
      const { response, error: downloadError } = await fetchData({
        method: 'GET',
        url: `/core/private/consumer-meter/supplier/hhd?${parseQuery({
          meterId: tenderInvitation.consumerTender.consumerMeter.id,
          fileName: file.blob.fileName,
        })}`,
        responseType: 'json',
      })

      if (downloadError) throw downloadError

      const base64Data = response?.data.hhdFile

      const binaryResponse = await fetch(
        `data:application/octet-stream;base64,${base64Data}`,
      )
      const binaryData = await binaryResponse.blob()

      const blob = new Blob([binaryData], {
        type: 'application/octet-stream',
      })

      const url = URL.createObjectURL(blob)

      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.setAttribute('download', file.blob.fileName)
      downloadLink.target = '_blank'
      downloadLink.click()

      URL.revokeObjectURL(url)
    } catch (err) {
      throwFeedbackError({ err })
    } finally {
      setIsDownloadingHhdFiles(false)
    }
  }

  const handleFetchLoaFiles = async () => {
    if (isLoadingLoaFiles) return
    setIsLoadingLoaFiles(true)

    try {
      const { response, error: fetchError } = await fetchData({
        method: 'GET',
        url: `/core/private/consumer-meter/supplier/loa/list?${parseQuery({
          consumerTenderId: tenderInvitation.consumerTenderId,
        })}`,
        responseType: 'json',
      })

      if (fetchError) throw fetchError

      const files = response?.data.loaFiles || []

      const processedFiles = await Promise.all(
        files.map(async (fileObject: { file: string; fileName: string }) => {
          const base64Data = fileObject.file || ''
          const fileNameFromResponse =
            fileObject.fileName || 'defaultFileName.pdf'

          const binaryResponse = await fetch(
            `data:application/octet-stream;base64,${base64Data}`,
          )
          const binaryData = await binaryResponse.blob()

          const blob = new Blob([binaryData], {
            type: 'application/octet-stream',
          })

          return { blob, fileName: fileNameFromResponse }
        }),
      )

      setFetchedLoaFiles(processedFiles)
    } catch (err: any) {
      throwFeedbackError(err)
    } finally {
      setIsLoadingLoaFiles(false)
    }
  }

  const handleDownloadLoa = async (file: any) => {
    if (isDownloadingLoaFiles) return

    try {
      const { response, error: downloadError } = await fetchData({
        method: 'GET',
        url: `/core/private/consumer-meter/supplier/loa?${parseQuery({
          fileName: file.blob.fileName,
          meterId: tenderInvitation.consumerTender.consumerMeterId,
        })}`,
        responseType: 'json',
      })

      if (downloadError) throw downloadError

      const base64Data = response?.data.loaFile

      const binaryResponse = await fetch(
        `data:application/octet-stream;base64,${base64Data}`,
      )
      const binaryData = await binaryResponse.blob()

      const blob = new Blob([binaryData], {
        type: 'application/octet-stream',
      })

      const url = URL.createObjectURL(blob)

      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.setAttribute('download', file.name)
      downloadLink.target = '_blank'
      downloadLink.click()

      URL.revokeObjectURL(url)
    } catch (err: any) {
      throwFeedbackError(err)
    } finally {
      setIsDownloadingLoaFiles(false)
    }
  }

  useEffect(() => {
    const fetchAdminHasLoa = async () => {
      try {
        const { response, error: err } = await fetchData({
          method: 'GET',
          url: `/core/private/consumer-meter/supplier/has-admin-loa?${parseQuery(
            {
              meterId: tenderInvitation.consumerTender.consumerMeterId,
            },
          )}`,
        })

        if (err || !response) throw err

        setAdminHasLoa(response.data.hasLOA)
      } catch (err: any) {
        throwFeedbackError({ err, context: 'consumerTender' })
      }
    }

    fetchAdminHasLoa()
  }, [tenderInvitation.consumerTender])

  useEffect(() => {
    handleFetchHhdFiles()
  }, [])

  useEffect(() => {
    handleFetchLoaFiles()
  }, [])

  return (
    <section
      className={classNames(
        container,
        breakPointsContainer,
        'pt-12 relative gap-y-8',
      )}
    >
      <div className="absolute top-0 left-0 -mx-3 hover:brightness-50 cursor-pointer">
        <Button
          variant="text"
          icon={<ChevronLeftIcon />}
          onClick={() =>
            navigate(
              `/consumerTenders/status/${
                mappedTenderStatus[
                  tenderInvitation.consumerTender.status as AvailableRouteStatus
                ]
              }`,
            )
          }
        >
          {t('backButton', {
            status: capitalizeText(
              tenderInvitation.consumerTender.status.replace('_', ' '),
            ),
          })}
        </Button>
      </div>

      <div className="flex flex-col gap-y-8 w-full">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center justify-start">
            <h1 className="text-3xl font-semibold text-ppa/title mr-5 w-full">
              {tenderInvitation.consumerTender.shortId}
            </h1>
            <TenderInvitationPill
              status={tenderInvitation.consumerTender.status}
              invitationStatus={
                tenderInvitation.status as TenderInvitationStatus
              }
              bidStatus={tenderInvitation.consumerTenderBid?.status}
            />
          </div>
        </div>

        <ConsumerTenderDetailsDefList data={tenderInvitation.consumerTender} />

        <div className="flex flex-col w-full">
          <div className="flex items-end mb-5">
            <h1 className="text-lg font-medium text-ppa/title mr-3">
              {t('hhd.title')}
            </h1>
          </div>
          <CollapseList
            title={t('hhd.collapseList.title')}
            titleEmptyList={t('hhd.collapseList.filesNotFound')}
            list={
              hhdFiles && hhdFiles.length > 0
                ? hhdFiles.map((file, index) => ({
                    id: `hhdFile-${index}`,
                    name: file.fileName,
                    blob: file,
                  }))
                : []
            }
            defaultOpen
            rowKeys={[
              { keyName: 'name' },
              {
                keyName: 'actions',
                containerClass: 'ml-auto pl-2 max-w-[50px]',
                renderCustomEl: (file: any) => {
                  return (
                    <div className="flex items-center gap-x-2">
                      <DownloadIcon
                        className={classNames(
                          'w-5 h-5',
                          'stroke-ppa/primary hover:brightness-75 cursor-pointer ',
                        )}
                        onClick={() => handleDownloadHhd(file)}
                      />
                    </div>
                  )
                },
              },
            ]}
          />

          <div className="my-5">
            <DownloadHhd
              mpan={
                tenderInvitation.consumerTender.consumerMeter.mpan as string
              }
              adminHasLoa={adminHasLoa}
            />
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex items-end mb-5">
            <h1 className="text-lg font-medium text-ppa/title mr-3">
              {t('loa.title')}
            </h1>
          </div>
          <CollapseList
            title={t('loa.collapseList.title')}
            titleEmptyList={t('loa.collapseList.filesNotFound')}
            list={
              fetchedLoaFiles && fetchedLoaFiles.length > 0
                ? fetchedLoaFiles.map((file, index) => ({
                    id: `loaFile-${index}`,
                    name: file.fileName,
                    blob: file,
                  }))
                : []
            }
            defaultOpen
            rowKeys={[
              { keyName: 'name' },
              {
                keyName: 'actions',
                containerClass: 'ml-auto pl-2 max-w-[50px]',
                renderCustomEl: (file: any) => {
                  return (
                    <div className="flex items-center gap-x-2">
                      <DownloadIcon
                        className="w-5 h-5 cursor-pointer hover:brightness-75 stroke-ppa/primary"
                        onClick={() => handleDownloadLoa(file)}
                      />
                    </div>
                  )
                },
              },
            ]}
          />
        </div>

        <SiteDetailsDefList
          data={tenderInvitation.consumerTender.consumerMeter}
        />

        {buttons && (
          <div className="flex items-center justify-start gap-x-1 w-full">
            {buttons.map((item) => (
              <div className="w-full flex max-w-[165px]" key={item.text}>
                <Button
                  {...item.props}
                  key={item.text}
                  onClick={() => item.onClick()}
                >
                  {item.text}
                </Button>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  )
}

export default ConsumerTenderDetails
