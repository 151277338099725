import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { UserProvider } from './hooks/use-user'
import { NotificationProvider } from './hooks/use-read-notifications'

import Auth0ProviderWithHistory from './providers/auth0-provider-with-history'

import RouteTracker from './components/atoms/route-tracker'

import './utils/features'
import Routes from './routes'
import './styles/global.css'
import './locales/index'

export const App: React.FC = () => {
  useEffect(() => {
    document
      .getElementsByTagName('html')[0]
      .setAttribute('data-theme', process.env.REACT_APP_COMPANY || 'PPAYA')
  }, [])

  return (
    <BrowserRouter>
      <RouteTracker />
      <Auth0ProviderWithHistory>
        <UserProvider>
          <NotificationProvider>
            <Routes />
          </NotificationProvider>
        </UserProvider>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  )
}

export default App
