import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'

import { DownloadIcon } from '@heroicons/react/outline'

import usePPA from '../../../hooks/use-ppa'

import { dateFormats } from '../../../utils/data'

import { feedbackMessage } from '../../atoms/feedback'

import InputGroup from '../../molecules/input-group'
import Input from '../../atoms/input'
import Button from '../../atoms/button'

interface DownloadHhdProps {
  mpan: string
  adminHasLoa?: boolean
}

type FormValues = {
  startDate: string
  endDate: string
}

const DownloadHhd: React.FC<DownloadHhdProps> = ({
  mpan,
  adminHasLoa = true,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'hhdDownload',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData } = usePPA()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      startDate: dayjs().subtract(1, 'year').format(dateFormats.aws),
      endDate: dayjs().format(dateFormats.aws),
    },
  })

  const generateCsvContent = (data: any): string => {
    let csvContent = `MPAN, Date (YYYY-MM-DD),${Array.from(
      { length: 50 },
      (_, i) => `P${i + 1}`,
    ).join(',')}\n`

    const sortedData = data.sort(
      (a: any, b: any) =>
        new Date(a.date).getTime() - new Date(b.date).getTime(),
    )

    sortedData.forEach(({ mpan: currMpan, date, periods }: any) => {
      const periodValues = []
      for (let i = 1; i < 51; i += 1) {
        periodValues.push(periods[`P${i}`] || null)
      }
      csvContent += `${currMpan},${date},${periodValues.join(',')}\n`
    })

    // Return the generated CSV string
    return csvContent
  }

  const downloadCsv = (csvContent: string, filename: string) => {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', filename)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const onSubmit = async (data: any) => {
    const { startDate, endDate } = data

    try {
      const { response, error } = await fetchData({
        method: 'POST',
        url: '/hhd-data/private/meter/real-time-data',
        body: {
          mpan,
          startDate,
          endDate,
        },
      })
      if (error || !response) throw error

      // Generate CSV content
      const csvContent = generateCsvContent(response?.data.data)

      // Trigger the download
      downloadCsv(csvContent, `hhd_${startDate}_${endDate}.csv`)
    } catch (err) {
      feedbackMessage(
        {
          title: tUtils('feedbackMessage.error.title'),
          description: t('feedbackMessage.error.description'),
        },
        'error',
      )
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col w-full gap-y-5 max-w-[500px]"
    >
      <h1 className="text-lg font-medium text-ppa/title mr-3">{t('title')}</h1>
      <div className="flex gap-x-6 items-end justify-center">
        <Controller
          name="startDate"
          control={control}
          render={({ field: props }) => (
            <InputGroup
              label={t('fields.startDate')}
              error={errors.startDate?.message}
              required
            >
              <Input
                type="date"
                {...props}
                min={dayjs().subtract(1, 'year').format(dateFormats.aws)}
                placeholder={t('fields.startDate')}
                error={errors.startDate?.message}
                disabled={!adminHasLoa}
                smallInput
              />
            </InputGroup>
          )}
        />
        <Controller
          name="endDate"
          control={control}
          render={({ field: props }) => (
            <InputGroup
              label={t('fields.endDate')}
              error={errors.endDate?.message}
              required
            >
              <Input
                type="date"
                {...props}
                max={dayjs().format(dateFormats.aws)}
                placeholder={t('fields.endDate')}
                error={errors.endDate?.message}
                disabled={!adminHasLoa}
                smallInput
              />
            </InputGroup>
          )}
        />
        <div className="w-max">
          <Button
            type="submit"
            variant={!adminHasLoa ? 'quinary' : 'primary'}
            icon={<DownloadIcon />}
            disabled={!adminHasLoa}
            fullWidth
            smallLabel
          >
            {t('downloadButton')}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default DownloadHhd
