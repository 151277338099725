import React, { useState, useCallback } from 'react'
import { useInView } from 'react-intersection-observer'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import dayjs from 'dayjs'

import useUser from '../../../hooks/use-user'
import usePPA from '../../../hooks/use-ppa'
import useNotification from '../../../hooks/use-read-notifications'

import { transition } from '../../../styles/index'

import { dateFormats } from '../../../utils/data'

import { NotificationMessage } from '../../../types'

import { ReactComponent as MessageUnreadIcon } from './assets/messageUnread.svg'
import { ReactComponent as MessageReadIcon } from './assets/messageRead.svg'
import { ReactComponent as TrashIcon } from './assets/trash.svg'
import { ReactComponent as TendersIcon } from './assets/iconTender.svg'
import { ReactComponent as BidsIcon } from './assets/iconBid.svg'
import { ReactComponent as DocumentsIcon } from './assets/iconDocument.svg'
import { ReactComponent as UserIcon } from './assets/iconUser.svg'
import { ReactComponent as SitesIcon } from './assets/iconSite.svg'

import { ReactComponent as Spinner } from '../../../assets/spinner/spinner.svg'

import Checkbox from '../../../components/atoms/checkbox'
import { feedbackMessage } from '../../../components/atoms/feedback'

type IconKeys =
  | 'TENDER'
  | 'SUPPLIER_TENDER'
  | 'CONSUMER_TENDER'
  | 'BID'
  | 'CONSUMER_TENDER_BID'
  | 'SUPPLIER_TENDER_BID'
  | 'SUPPLIER'
  | 'GENERATOR'
  | 'CONSUMER'
  | 'ADMIN'
  | 'SITE'
  | 'CONSUMER_METER'
  | 'DOCUMENT'

type MappedIcons = Record<IconKeys, JSX.Element>

const contextIcon: MappedIcons = {
  TENDER: <TendersIcon />,
  SUPPLIER_TENDER: <TendersIcon />,
  CONSUMER_TENDER: <TendersIcon />,
  BID: <BidsIcon />,
  CONSUMER_TENDER_BID: <BidsIcon />,
  SUPPLIER_TENDER_BID: <BidsIcon />,
  SUPPLIER: <UserIcon />,
  GENERATOR: <UserIcon />,
  CONSUMER: <UserIcon />,
  ADMIN: <UserIcon />,
  SITE: <SitesIcon />,
  CONSUMER_METER: <SitesIcon />,
  DOCUMENT: <DocumentsIcon />,
}

type MappedRoutes = {
  [context: string]: {
    [action: string]: (notification: NotificationMessage) => string | undefined
  }
}

const exportTenderDefaultRoutes = (notification: NotificationMessage) => {
  const { tender, tenderInvitation } = notification.data

  if (!tender && !tenderInvitation) return undefined
  if (tender) {
    return `/tenders/${tender.id}`
  }
  return `/tenders/${tenderInvitation?.tender?.id}`
}

const sleevedTenderDefaultRoutes = (notification: NotificationMessage) => {
  const { supplierTender } = notification.data

  if (!supplierTender) return undefined
  return `/sleevedTenders/${supplierTender.id}`
}

const siteDefaultRoutes = (notification: NotificationMessage) => {
  const { site } = notification.data

  if (!site) return undefined
  return `/sites/${site.id}`
}

const meterDefaultRoutes = (notification: NotificationMessage) => {
  const { consumerMeter } = notification.data

  if (!consumerMeter) return undefined
  return `/meters/${consumerMeter.id}`
}

const generatorDefaultRoutes = (notification: NotificationMessage) => {
  const { generator } = notification.data

  if (!generator) return undefined
  return `/generators/${generator.id}`
}

const supplierDefaultRoutes = (notification: NotificationMessage) => {
  const { supplier } = notification.data

  if (!supplier) return undefined
  return `/suppliers/${supplier.id}`
}

const consumerDefaultRoutes = (notification: NotificationMessage) => {
  const { user } = notification.data

  if (!user) return undefined
  return `/consumers/${user.id}`
}

interface NotificationListProps {
  isLoading: boolean
  notifications?: NotificationMessage[]
  refetch: () => void
  loadMore: () => Promise<void>
}

const NotificationsList: React.FC<NotificationListProps> = ({
  isLoading,
  notifications,
  refetch,
  loadMore,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'notifications',
  })

  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { user } = useUser<'ADMIN' | 'GENERATOR' | 'SUPPLIER' | 'CONSUMER'>()
  const { userType } = user.appMetadata

  const navigate = useNavigate()

  const { fetchData } = usePPA()
  const { handleNotificationsChange } = useNotification()

  const [selectedNotifications, setSelectedNotifications] = useState<string[]>(
    [],
  )
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  const importTenderDefaultRoutes = (notification: NotificationMessage) => {
    const { consumerTender, consumerTenderBid } = notification.data

    if (!consumerTender && !consumerTenderBid) {
      return undefined
    }

    if (consumerTender) {
      if (userType !== 'CONSUMER') {
        return `/consumerTenders/${consumerTender.id}`
      }
      return `/tenders/${consumerTender.id}`
    }

    if (consumerTenderBid) {
      const tenderId =
        consumerTenderBid.consumerTenderInvitation?.consumerTender?.id
      if (userType !== 'CONSUMER') {
        return `/consumerTenders/${tenderId}`
      }
      return `/tenders/${tenderId}`
    }

    return undefined
  }

  const mappedRoutes: MappedRoutes = {
    TENDER: {
      CREATED: exportTenderDefaultRoutes,
      EXECUTED: exportTenderDefaultRoutes,
      SET_DEADLINE: exportTenderDefaultRoutes,
      ACTIVATED: exportTenderDefaultRoutes,
      TARGET_PRICE_REACHED: exportTenderDefaultRoutes,
      LOST: exportTenderDefaultRoutes,
      UPDATED: exportTenderDefaultRoutes,
      DELETED: exportTenderDefaultRoutes,
      WITHDRAWN: exportTenderDefaultRoutes,
      SET_TARGET_PRICE: exportTenderDefaultRoutes,
      UPDATED_STATUS: exportTenderDefaultRoutes,
      UNDER_OFFER: exportTenderDefaultRoutes,
      ACTIVATION_PENDING: exportTenderDefaultRoutes,
      ACTIVATION_APPROVED: exportTenderDefaultRoutes,
    },
    CONSUMER_TENDER: {
      CREATED: importTenderDefaultRoutes,
      EXECUTED: importTenderDefaultRoutes,
      SET_DEADLINE: importTenderDefaultRoutes,
      ACTIVATED: importTenderDefaultRoutes,
      LOST: importTenderDefaultRoutes,
      UPDATED: importTenderDefaultRoutes,
      DELETED: importTenderDefaultRoutes,
      WITHDRAWN: importTenderDefaultRoutes,
      UPDATED_STATUS: importTenderDefaultRoutes,
      UNDER_OFFER: importTenderDefaultRoutes,
      ACTIVATION_PENDING: importTenderDefaultRoutes,
      ACTIVATION_APPROVED: importTenderDefaultRoutes,
    },
    SUPPLIER_TENDER: {
      CREATED: sleevedTenderDefaultRoutes,
      EXECUTED: sleevedTenderDefaultRoutes,
      SET_DEADLINE: sleevedTenderDefaultRoutes,
      ACTIVATED: sleevedTenderDefaultRoutes,
      LOST: sleevedTenderDefaultRoutes,
      UPDATED: sleevedTenderDefaultRoutes,
      DELETED: sleevedTenderDefaultRoutes,
      WITHDRAWN: sleevedTenderDefaultRoutes,
      UPDATED_STATUS: sleevedTenderDefaultRoutes,
      UNDER_OFFER: sleevedTenderDefaultRoutes,
      ACTIVATION_PENDING: sleevedTenderDefaultRoutes,
      ACTIVATION_APPROVED: importTenderDefaultRoutes,
    },
    BID: {
      CREATED: exportTenderDefaultRoutes,
      ACCEPTED: exportTenderDefaultRoutes,
      REVOKED: exportTenderDefaultRoutes,
      REJECTED: exportTenderDefaultRoutes,
      EDITED: exportTenderDefaultRoutes,
      NOT_ACCEPTED: exportTenderDefaultRoutes,
    },
    CONSUMER_TENDER_BID: {
      CREATED: importTenderDefaultRoutes,
      ACCEPTED: importTenderDefaultRoutes,
      REVOKED: importTenderDefaultRoutes,
      REJECTED: importTenderDefaultRoutes,
      EDITED: importTenderDefaultRoutes,
      NOT_ACCEPTED: importTenderDefaultRoutes,
    },
    SITE: {
      CREATED: siteDefaultRoutes,
      UPDATED: siteDefaultRoutes,
    },
    CONSUMER_METER: {
      CREATED: meterDefaultRoutes,
      UPDATED: meterDefaultRoutes,
    },
    SUPPLIER: {
      SIGN_UP: supplierDefaultRoutes,
    },
    GENERATOR: {
      SIGN_UP: generatorDefaultRoutes,
    },
    CONSUMER: {
      SIGN_UP: consumerDefaultRoutes,
    },
  }

  const notificationPath = (notification: NotificationMessage) => {
    const { context, action } = notification

    const contextRoutes = mappedRoutes[context]
    if (contextRoutes) {
      const actionRoute = contextRoutes[action]
      if (actionRoute) {
        const route = actionRoute(notification)
        if (route) return route
      }
    }

    return undefined
  }

  const handleDeleteNotification = async () => {
    if (isLoading || selectedNotifications.length === 0) return

    try {
      const { response, error } = await fetchData({
        method: 'PATCH',
        url: '/notifications/private/notifications/delete',
        body: {
          notificationIds: selectedNotifications,
        },
      })

      if (error || !response?.data) throw error

      refetch()
      setSelectedNotifications([])

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
        },
        'success',
      )
      try {
        await handleNotificationsChange()
      } catch (err: any) {
        console.error(err)
      }
    } catch (err) {
      console.error(err)

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.error.title'),
          description: tUtils('feedbackMessage.error.description'),
        },
        'error',
      )
    }
  }

  const handleReadNotification = async (
    notificationId?: string,
    doRefetch = true,
  ) => {
    if (isLoading || (!notificationId && selectedNotifications.length === 0))
      return

    const idsToMarkAsRead = notificationId
      ? [notificationId]
      : selectedNotifications

    try {
      const { response, error } = await fetchData({
        method: 'PATCH',
        url: '/notifications/private/notifications/read',
        body: {
          notificationIds: idsToMarkAsRead,
        },
      })

      if (error || !response?.data) throw error

      if (doRefetch) {
        refetch()
        setSelectedNotifications([])
      }
      try {
        await handleNotificationsChange()
      } catch (err: any) {
        console.error(err)
      }
    } catch (err: any) {
      console.error(err)

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.error.title'),
          description: tUtils('feedbackMessage.error.description'),
        },
        'error',
      )
    }
  }

  const handleUnreadNotification = async () => {
    if (isLoading || selectedNotifications.length === 0) return

    try {
      const { response, error } = await fetchData({
        method: 'PATCH',
        url: '/notifications/private/notifications/unread',
        body: {
          notificationIds: selectedNotifications,
        },
      })

      if (error || !response?.data) throw error

      refetch()
      setSelectedNotifications([])

      try {
        await handleNotificationsChange()
      } catch (err: any) {
        console.error(err)
      }
    } catch (err: any) {
      console.error(err)

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.error.title'),
          description: tUtils('feedbackMessage.error.description'),
        },
        'error',
      )
    }
  }

  const handleCheckboxClick = (notificationId: string) => {
    const isAlreadySelected = selectedNotifications.includes(notificationId)

    const updatedNotifications = isAlreadySelected
      ? selectedNotifications.filter((id) => id !== notificationId)
      : [...selectedNotifications, notificationId]

    setSelectedNotifications(updatedNotifications)
  }

  const handleSelectAll = () => {
    if (!notifications) return
    const notificationsLen = notifications.length

    if (
      selectedNotifications.length === 0 ||
      notificationsLen !== selectedNotifications.length
    ) {
      setSelectedNotifications(notifications.map((item) => item.id))
      return
    }

    setSelectedNotifications([])
  }

  const callLoadMore = useCallback(
    async (inView: boolean) => {
      /**
       * !loadMore => not tracking loadMore
       * isLoadingMore => it's already loading more
       * !inView => the element it's not in View yet
       */
      if (!loadMore || isLoadingMore || !inView) return

      setIsLoadingMore(true)

      await loadMore()

      setIsLoadingMore(false)
    },
    [loadMore, isLoadingMore, refetch],
  )

  const { ref } = useInView({
    onChange: callLoadMore,
    threshold: 0,
    rootMargin: '-50px 0px 0px 0px',
    triggerOnce: false,
  })

  return (
    <div className="flex flex-col mt-2 relative">
      <div className="flex gap-x-12 mb-5 lg:justify-between text-xs font-normal">
        <label
          htmlFor="checkbox"
          className="flex items-center text-ppa/placeholder"
        >
          <input
            id="checkbox"
            type="checkbox"
            onClick={handleSelectAll}
            checked={
              !!(
                notifications &&
                selectedNotifications.length === notifications.length
              )
            }
            className="mr-3"
          />
          {t('actions.selectAll')}
        </label>

        <div className="flex gap-5 text-ppa/secondary">
          <button
            type="button"
            onClick={handleUnreadNotification}
            className="flex items-center gap-x-1"
          >
            <MessageUnreadIcon className="mr-1" />
            {t('actions.markAsUnread')}
          </button>
          <button
            type="button"
            onClick={() => handleReadNotification()}
            className="flex items-center gap-x-1"
          >
            <MessageReadIcon className="mr-1" />
            {t('actions.markAsRead')}
          </button>
          <button
            type="button"
            onClick={handleDeleteNotification}
            className="flex items-center gap-x-1"
          >
            <TrashIcon className="mr-1" />
            {t('actions.delete')}
          </button>
        </div>
      </div>
      {notifications?.map((notification) => (
        <div
          key={notification.id}
          className={classNames(
            'flex justify-start items-center gap-x-5 gap-y-7 border-b',
            transition,
            !selectedNotifications.includes(notification.id)
              ? 'bg-transparent'
              : 'bg-ppa/tableRowHover',
          )}
        >
          <Checkbox
            checked={selectedNotifications.includes(notification.id)}
            onClick={() => handleCheckboxClick(notification.id)}
          />
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
          jsx-a11y/no-static-element-interactions */}
          <div
            className="flex w-full overflow-hidden py-3 px-1 cursor-pointer"
            onClick={async () => {
              try {
                await handleReadNotification(notification.id, false)
              } catch (error) {
                console.error(error)
              }

              const destination = notificationPath(notification)
              if (destination) {
                navigate(destination)
              } else {
                console.error(
                  'notificationPath(notification) returned undefined',
                )
                feedbackMessage(
                  {
                    title: tUtils('feedbackMessage.error.title'),
                    description: tUtils('feedbackMessage.error.description'),
                  },
                  'error',
                )
              }
            }}
          >
            <span className="flex items-center">
              {!notification.read && (
                <div
                  className={classNames(
                    'self-start h-[0.7em] w-[0.7em] rounded-full mt-1 mr-[-11px] z-50',
                    transition,
                    !notification.read ? 'bg-ppa/warning' : 'bg-transparent',
                  )}
                />
              )}
              {contextIcon[notification.context as IconKeys]}
            </span>
            <div className="flex-col text-ppa/secondary ml-5">
              <span className="font-medium">
                {t(`message.context.${notification.context}`)}&nbsp;
                {t(`message.action.${notification.action}`)}
              </span>
              <span className="flex text-xs font-normal">
                {t(
                  `message.description.${userType}_${notification.context}_${notification.action}`,
                )}
              </span>
            </div>
          </div>
          <span className="text-xs text-ppa/placeholder font-normal">
            {dayjs(notification.createdAt).format(dateFormats.userDateTime)}
          </span>
        </div>
      ))}
      {notifications && isLoadingMore && (
        <div className={classNames('flex flex-col items-center mt-2')}>
          <Spinner className="mx-auto animate-spin w-5 h-5" />
        </div>
      )}
      {notifications && notifications.length > 0 && !isLoadingMore && (
        <div ref={ref} className="flex w-full absolute bottom-5" />
      )}
    </div>
  )
}

export default NotificationsList
