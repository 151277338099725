import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { DownloadIcon } from '@heroicons/react/outline'

import useUser from '../../../hooks/use-user'
import usePPA from '../../../hooks/use-ppa'
import { parseQuery } from '../../../hooks/use-ppa/use-ppa-get-list'

import CollapseList from '../../molecules/collapse-list'

interface HistoricalHhdDownloadProps {
  context: 'SITE' | 'METER'
  id: string
}
const HistoricalHhdDownload: React.FC<HistoricalHhdDownloadProps> = ({
  context,
  id,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'components.organisms.historicalHhd',
  })
  const { user } = useUser()
  const { fetchData, throwFeedbackError } = usePPA()

  const [isLoadingHhdFiles, setIsLoadingHhdFiles] = useState(false)
  const [hhdFiles, setHhdFiles] = useState<File[] | undefined>()
  const [isDownloadingHhdFiles, setIsDownloadingHhdFiles] = useState(false)

  const contextUrl = useMemo(() => {
    if (user.appMetadata.userType === 'GENERATOR') return ''
    return `/${user.appMetadata.userType.toLowerCase()}`
  }, [])

  const getFetchHhdUrl = () => {
    if (context === 'SITE')
      return `/core/private/site${contextUrl}/hhd/list?${parseQuery({
        siteId: id,
      })}`
    return `/core/private/consumer-meter${contextUrl}/hhd/list?${parseQuery({
      meterId: id,
    })}`
  }

  const handleFetchHhdFiles = async () => {
    if (isLoadingHhdFiles) return
    setIsLoadingHhdFiles(true)

    try {
      const { response, error: fetchError } = await fetchData({
        method: 'GET',
        url: getFetchHhdUrl(),
        responseType: 'json',
      })

      if (fetchError) throw fetchError

      const files = response?.data.hhdFiles || []

      const processedFiles = await Promise.all(
        files.map(async (fileObject: { file: string; fileName: string }) => {
          const base64Data = fileObject.file || ''
          const fileNameFromResponse =
            fileObject.fileName || 'defaultFileName.pdf'

          const binaryResponse = await fetch(
            `data:application/octet-stream;base64,${base64Data}`,
          )
          const binaryData = await binaryResponse.blob()

          const blob = new Blob([binaryData], {
            type: 'application/octet-stream',
          })

          return { blob, fileName: fileNameFromResponse }
        }),
      )

      setHhdFiles(processedFiles)
    } catch (err: any) {
      throwFeedbackError(err)
    } finally {
      setIsLoadingHhdFiles(false)
    }
  }

  useEffect(() => {
    handleFetchHhdFiles()
  }, [])

  const getDownloadURL = (fileName: string) => {
    if (context === 'SITE')
      return `/core/private/site${contextUrl}/hhd?${parseQuery({
        siteId: id,
        fileName,
      })}`
    return `/core/private/consumer-meter${contextUrl}/hhd?${parseQuery({
      meterId: id,
      fileName,
    })}`
  }

  const handleDownloadHhd = async (file: any) => {
    if (isDownloadingHhdFiles) return

    try {
      const { response, error: downloadError } = await fetchData({
        method: 'GET',
        url: getDownloadURL(file.blob.fileName),
        responseType: 'json',
      })

      if (downloadError) throw downloadError

      const base64Data = response?.data.hhdFile

      const binaryResponse = await fetch(
        `data:application/octet-stream;base64,${base64Data}`,
      )
      const binaryData = await binaryResponse.blob()

      const blob = new Blob([binaryData], {
        type: 'application/octet-stream',
      })

      const url = URL.createObjectURL(blob)

      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.setAttribute('download', file.blob.fileName)
      downloadLink.target = '_blank'
      downloadLink.click()

      URL.revokeObjectURL(url)
    } catch (err) {
      throwFeedbackError({ err })
    } finally {
      setIsDownloadingHhdFiles(false)
    }
  }

  return (
    <>
      <h1 className="text-lg font-medium text-ppa/title mr-3 mb-5">
        {t('title')}
      </h1>
      <CollapseList
        title={t('collapseList.title')}
        titleEmptyList={t('collapseList.emptyList')}
        list={
          hhdFiles && hhdFiles.length > 0
            ? hhdFiles.map((file: any, index) => ({
                id: `hhdFile-${index}`,
                name: file.fileName,
                blob: file,
              }))
            : []
        }
        defaultOpen
        rowKeys={[
          { keyName: 'name' },
          {
            keyName: 'actions',
            containerClass: 'ml-auto pl-2 max-w-[50px]',
            renderCustomEl: (file: any) => {
              return (
                <div className="flex items-center gap-x-2">
                  <DownloadIcon
                    className="w-5 h-5 cursor-pointer hover:brightness-75 stroke-ppa/primary"
                    onClick={() => handleDownloadHhd(file)}
                  />
                </div>
              )
            },
          },
        ]}
      />
    </>
  )
}

export default HistoricalHhdDownload
