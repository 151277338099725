import React, { useState, useEffect } from 'react'
import { v4 } from 'uuid'

import usePPA from '../../../hooks/use-ppa'

import Chat from './chat'

interface Message {
  id: string
  sender: 'user' | 'assistant'
  text: string
}

const defaultAssistantMessage: Message = {
  id: v4(),
  sender: 'assistant',
  text: 'What can I help with?',
}

const AiAssistant: React.FC = () => {
  const { fetchData } = usePPA()

  const [isOpen, setIsOpen] = useState(() => {
    return JSON.parse(localStorage.getItem('chatOpen') || 'false')
  })

  const [isSendingMessage, setIsSendingMessage] = useState<boolean>(false)
  const [messages, setMessages] = useState<Message[]>([defaultAssistantMessage])
  const [threadId, setThreadId] = useState<string | null>(null)
  const [assistantId, setAssistantId] = useState<string | null>(null)

  const handleSendMessage = async (message: string) => {
    if (isSendingMessage || !message.trim()) return
    setIsSendingMessage(true)

    try {
      const userMessage: Message = {
        id: v4(),
        sender: 'user',
        text: message,
      }
      setMessages((prev) => [...prev, userMessage])

      const { response, error } = await fetchData({
        method: 'POST',
        url: '/ai-assistant/private/',
        body: {
          userInput: message,
          threadId: threadId || null,
          assistantId: assistantId || null,
        },
      })

      if (response?.data.result && !error) {
        setThreadId(response.data.result.threadId)
        setAssistantId(response.data.result.assistantId)

        if (response.data.result.response) {
          const assistantMessage: Message = {
            id: v4(),
            sender: 'assistant',
            text: response.data.result.response,
          }
          setMessages((prev) => [...prev, assistantMessage])
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsSendingMessage(false)
    }
  }

  useEffect(() => {
    localStorage.setItem('chatOpen', JSON.stringify(isOpen))
  }, [isOpen])

  return (
    <Chat
      messages={messages}
      onSend={handleSendMessage}
      loading={isSendingMessage}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  )
}

export default AiAssistant
