import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import usePPA from '../../../../../../hooks/use-ppa'

import { LoaFile } from '../../../../../../types'

import { feedbackMessage } from '../../../../../../components/atoms/feedback'

import LoaForm, {
  LoaFormRef,
} from '../../../../../../components/organisms/loa-upload-form'

interface UploadLoaProps {
  siteId?: string
  isDrawerOpen: boolean
  onSuccess: () => void
  onCancel: () => void
}

const UploadLoa: React.FC<UploadLoaProps> = ({
  siteId,
  isDrawerOpen,
  onSuccess,
  onCancel,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.sites.details.loaDrawer',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const loaForm = useRef<LoaFormRef>(null)

  const [isLoading, setIsLoading] = useState(false)

  const handleUploadLoa = async (data: LoaFile) => {
    if (isLoading) return
    setIsLoading(true)

    try {
      if (!siteId) throw Error('Missing company ID')

      const formData = new FormData()
      formData.append('siteId', siteId)
      formData.append('fileName', data.loaFile.name as any)
      formData.append('file', data.loaFile as any)

      const { error } = await fetchData({
        method: 'POST',
        url: '/core/private/site/admin/admin-loa',
        body: formData,
        headers: {
          'Content-type': 'multipart/form-data',
        },
      })

      if (error) throw error

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('onUpload.success.description'),
        },
        'success',
      )

      onSuccess()
      loaForm.current?.resetForm()
    } catch (err) {
      throwFeedbackError({ err })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="flex flex-col gap-y-5 sm:w-[510px]">
      <h1 className="text-xl font-bold text-ppa/title">{t('uploadTitle')}</h1>

      {isDrawerOpen && (
        <LoaForm
          isLoading={isLoading}
          onSubmit={handleUploadLoa}
          onCancel={onCancel}
        />
      )}
    </div>
  )
}

export default UploadLoa
