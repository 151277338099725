import React, { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router-dom'

import {
  DownloadIcon,
  ChevronLeftIcon,
  UploadIcon,
  TrashIcon,
} from '@heroicons/react/outline'
import classNames from 'classnames'

import { ReactComponent as BrokerFeeIcon } from './assets/brokerFeeIcon.svg'
import { ReactComponent as ActAsIcon } from './assets/actAsIcon.svg'

import { parseSnippetAddress } from '../../../../utils'

import { ConsumerDetails } from '../consumer/details'

import { feedbackMessage } from '../../../../components/atoms/feedback'
import DefList from '../../../../components/atoms/def-list'
import Button from '../../../../components/atoms/button'
import SlidingDrawer from '../../../../components/molecules/sliding-drawer'

import useUser from '../../../../hooks/use-user'
import usePPA from '../../../../hooks/use-ppa'
import usePPAGetDetails from '../../../../hooks/use-ppa/use-ppa-get-details'
import { parseQuery } from '../../../../hooks/use-ppa/use-ppa-get-list'

import UpdateBrokerFeeDrawer from './components/broker-fee-drawer'
import CollapseList from '../../../../components/molecules/collapse-list'
import DownloadHhd from '../../../../components/organisms/latest-hhd-download'

import UploadLoa from './components/upload-loa'

import { Meter, MeterCompany } from '../../../../types'

import { container, breakPointsContainer, transition } from '../../../../styles'
import { ReactComponent as Spinner } from '../../../../assets/spinner/spinner.svg'

type File = Blob & {
  fileName: string
}

type LoaFile = {
  fileName: string
  blob: Blob
}

const MeterDetails: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.meters.details',
  })

  const { actAs } = useUser<'ADMIN'>()
  const navigate = useNavigate()

  const { meterId } = useParams<{ meterId: string }>()

  const { fetchData } = usePPA()

  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false)
  const [consumerDetails, setConsumerDetails] = useState<
    ConsumerDetails | undefined
  >()
  const [isLoadingConsumerDetails, setIsLoadingConsumerDetails] =
    useState<boolean>(false)

  const [hhdFiles, setHhdFiles] = useState<File[] | undefined>()
  const [isLoadingHhdFiles, setIsLoadingHhdFiles] = useState(false)

  const [isLoadingBillFiles, setIsLoadingBillFiles] = useState(false)
  const [fetchedBillFiles, setFetchedBillFiles] = useState<File[] | []>([])

  const [isDownloadingBillFiles, setIsDownloadingBillFiles] = useState(false)

  const [isLoadingAdminLoaFile, setIsLoadingAdminLoaFile] = useState(false)
  const [fetchedAdminLoaFile, setFetchedAdminLoaFile] =
    useState<LoaFile | null>(null)
  const [isLoaDrawerOpen, setIsLoaDrawerOpen] = useState(false)
  const [isDeletingLoaFile, setIsDeletingLoaFile] = useState(false)
  const [isDownloadingAdminLoaFile, setIsDownloadingAdminLoaFile] =
    useState(false)

  const [isLoadingLoaFiles, setIsLoadingLoaFiles] = useState(false)
  const [fetchedLoaFile, setFetchedLoaFile] = useState<LoaFile | null>(null)

  const [isDownloadingLoaFile, setIsDownloadingLoaFile] = useState(false)

  const [isLoadingContractFiles, setIsLoadingContractFiles] = useState(false)
  const [fetchedContractFiles, setFetchedContractFiles] = useState<File[] | []>(
    [],
  )

  const [isDownloadingContractFile, setIsDownloadingContractFile] =
    useState(false)

  const { isLoading, refetch, throwFeedbackError, data, error } =
    usePPAGetDetails<MeterCompany>({
      dataKey: 'meter',
      path: `/core/private/consumer-meter/admin/details/${meterId}`,
    })

  const meterDetails: MeterCompany | undefined = useMemo(() => {
    if (isLoading || error || !data) return undefined

    return data
  }, [data, isLoading, error])

  useEffect(() => {
    const fetchConsumerDetails = async () => {
      try {
        const { response, error: consumerDetailsError } = await fetchData({
          method: 'GET',
          url: `/core/private/consumer/admin/details/${meterDetails?.consumerId}`,
        })

        if (consumerDetailsError) {
          throw consumerDetailsError
        }

        setConsumerDetails(response?.data.consumer)
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoadingConsumerDetails(false)
      }
    }

    if (
      !meterDetails ||
      meterDetails.consumerId === undefined ||
      isLoadingConsumerDetails
    ) {
      return
    }

    setIsLoadingConsumerDetails(true)
    fetchConsumerDetails()
  }, [meterDetails])

  const handleActAs = () => {
    if (consumerDetails === undefined) return
    if (consumerDetails.status !== 'ACTIVE') {
      feedbackMessage(
        {
          title: 'You cannot do this action.',
          description:
            'Users that are not active cannot being used in "acting as".',
        },
        'warn',
      )
      return
    }

    const { id, name, members } = consumerDetails

    const filteredMembers = members.filter(
      (member) => member.memberType === 'OWNER',
    )
    if (filteredMembers.length === 0) {
      throw new Error('Something went wrong, member OWNER could not be found.')
    }

    actAs({
      appMetadata: {
        id,
        memberId: filteredMembers[0].id,
        memberType: filteredMembers[0].memberType,
        name,
        userType: 'CONSUMER',
        status: consumerDetails.status,
        userId: consumerDetails.userId,
        brokerContextName: consumerDetails.brokerContextName,
      },
    })

    navigate('/meters')
  }

  const rowKeys = useMemo(() => {
    return [
      { keyName: 'mpan', title: 'MPAN' },
      { keyName: 'hhd', title: 'HHD timeframe' },
      {
        keyName: 'annualConsumption',
        title: 'Average annual consumption',
        suffix: 'MWh',
      },
      {
        keyName: 'gridConnectionSize',
        title: 'Grid connection size',
        suffix: 'kVA',
      },
      {
        keyName: 'profileClass',
        title: 'Profile class',
        renderCustomEl: (item: Meter) => item.profileClass,
      },
      {
        keyName: 'company',
        title: 'Company',
        renderCustomEl: (item: Meter) => (
          <div className="flex flex-col">
            <div>{item.company?.name}</div>
            {item.company?.number && <div>{item.company?.number}</div>}
            {item?.company?.address && (
              <div>{parseSnippetAddress(item?.company?.address)}</div>
            )}
          </div>
        ),
      },
      {
        keyName: 'existingSupplier',
        title: 'Existing supplier',
        renderCustomEl: (item: Meter) => item.existingSupplier,
      },
      {
        keyName: 'brokerFee',
        title: 'Broker fee',
        suffix: '£/MWh',
        renderCustomEl: (item: Meter) => item.brokerFee,
      },
    ]
  }, [meterDetails])

  const handleFetchHhdFiles = async () => {
    if (isLoadingHhdFiles) return
    setIsLoadingHhdFiles(true)

    try {
      const { response, error: fetchError } = await fetchData({
        method: 'GET',
        url: `/core/private/consumer-meter/admin/hhd/list?${parseQuery({
          meterId,
          consumerId: meterDetails?.consumerId,
        })}`,
        responseType: 'json',
      })

      if (fetchError) throw fetchError

      const files = response?.data.hhdFiles || []

      const processedFiles = await Promise.all(
        files.map(async (fileObject: { file: string; fileName: string }) => {
          const base64Data = fileObject.file || ''
          const fileNameFromResponse =
            fileObject.fileName || 'defaultFileName.pdf'

          const binaryResponse = await fetch(
            `data:application/octet-stream;base64,${base64Data}`,
          )
          const binaryData = await binaryResponse.blob()

          const blob = new Blob([binaryData], {
            type: 'application/octet-stream',
          })

          return { blob, fileName: fileNameFromResponse }
        }),
      )

      setHhdFiles(processedFiles)
    } catch (err: any) {
      throwFeedbackError(err)
    } finally {
      setIsLoadingHhdFiles(false)
    }
  }

  const handleDownloadHHD = async (file: any) => {
    if (isLoadingHhdFiles) return

    try {
      const { response, error: downloadError } = await fetchData({
        method: 'GET',
        url: `/core/private/consumer-meter/admin/hhd?${parseQuery({
          fileName: file.name,
          meterId,
        })}`,
        responseType: 'json',
      })

      if (downloadError) throw downloadError

      const base64Data = response?.data.hhdFile

      const binaryResponse = await fetch(
        `data:application/octet-stream;base64,${base64Data}`,
      )
      const binaryData = await binaryResponse.blob()

      const blob = new Blob([binaryData], {
        type: 'application/octet-stream',
      })

      const url = URL.createObjectURL(blob)

      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.setAttribute('download', file.name)
      downloadLink.target = '_blank'
      downloadLink.click()

      URL.revokeObjectURL(url)
    } catch (err) {
      throwFeedbackError({ err })
    }
  }

  const rowKeysConsumer = useMemo(() => {
    return [
      {
        keyName: 'name',
        title: 'Name',
        renderCustomEl: (item: any) => {
          return item?.name
        },
      },
    ]
  }, [])

  const handleFetchBillFiles = async () => {
    if (isLoadingBillFiles) return
    setIsLoadingBillFiles(true)

    try {
      const { response, error: fetchError } = await fetchData({
        method: 'GET',
        url: `/core/private/consumer-meter/admin/bill/list?${parseQuery({
          meterId,
          consumerId: meterDetails?.consumerId,
        })}`,

        responseType: 'json',
      })

      if (fetchError) throw fetchError

      const billFiles = response?.data.billFiles || []

      const processedFiles = await Promise.all(
        billFiles.map(
          async (fileObject: { file: string; fileName: string }) => {
            const base64Data = fileObject.file || ''
            const fileNameFromResponse =
              fileObject.fileName || 'defaultFileName.pdf'

            const binaryResponse = await fetch(
              `data:application/octet-stream;base64,${base64Data}`,
            )
            const binaryData = await binaryResponse.blob()

            const blob = new Blob([binaryData], {
              type: 'application/octet-stream',
            })

            return { blob, fileName: fileNameFromResponse }
          },
        ),
      )

      setFetchedBillFiles(processedFiles)
    } catch (err: any) {
      throwFeedbackError(err)
    } finally {
      setIsLoadingBillFiles(false)
    }
  }

  const handleDownloadBill = async (file: any) => {
    if (isDownloadingBillFiles) return

    try {
      const { response, error: downloadError } = await fetchData({
        method: 'GET',
        url: `/core/private/consumer-meter/admin/bill?${parseQuery({
          meterId,
          fileName: file.blob.fileName,
        })}`,
        responseType: 'json',
      })

      if (downloadError) throw downloadError

      const base64Data = response?.data.billFile

      const binaryResponse = await fetch(
        `data:application/octet-stream;base64,${base64Data}`,
      )
      const binaryData = await binaryResponse.blob()

      const blob = new Blob([binaryData], {
        type: 'application/octet-stream',
      })

      const url = URL.createObjectURL(blob)

      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.setAttribute('download', file.blob.fileName)
      downloadLink.target = '_blank'
      downloadLink.click()

      URL.revokeObjectURL(url)
    } catch (err) {
      throwFeedbackError({ err })
    } finally {
      setIsDownloadingBillFiles(false)
    }
  }

  const handleFetchAdminLoaFile = async () => {
    if (isLoadingAdminLoaFile) return
    setIsLoadingAdminLoaFile(true)

    try {
      const { response, error: fetchError } = await fetchData({
        method: 'GET',
        url: `/core/private/consumer-meter/admin/admin-loa?${parseQuery({
          meterId,
        })}`,
        responseType: 'json',
      })

      if (fetchError) throw fetchError

      const base64Data = response?.data.LOAFile

      const fileNameFromResponse = response?.data.fileName || 'AdminloaFile.pdf'

      if (!base64Data) {
        setFetchedAdminLoaFile(null)
        return
      }

      const binaryResponse = await fetch(
        `data:application/octet-stream;base64,${base64Data}`,
      )

      const binaryData = await binaryResponse.blob()

      const blob = new Blob([binaryData], {
        type: 'application/octet-stream',
      })

      setFetchedAdminLoaFile({ fileName: fileNameFromResponse, blob })
    } catch (err: any) {
      throwFeedbackError(err)
    } finally {
      setIsLoadingAdminLoaFile(false)
    }
  }

  const handleDownloadAdminLoa = async (file: any) => {
    if (isDownloadingAdminLoaFile) return
    setIsDownloadingAdminLoaFile(true)

    try {
      const { response, error: downloadError } = await fetchData({
        method: 'GET',
        url: `/core/private/consumer-meter/admin/admin-loa?${parseQuery({
          meterId,
        })}`,
        responseType: 'json',
      })

      if (downloadError) throw downloadError

      const base64Data = response?.data.LOAFile

      const binaryResponse = await fetch(
        `data:application/octet-stream;base64,${base64Data}`,
      )
      const binaryData = await binaryResponse.blob()

      const blob = new Blob([binaryData], {
        type: 'application/octet-stream',
      })

      const url = URL.createObjectURL(blob)

      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.setAttribute('download', file.name)
      downloadLink.target = '_blank'
      downloadLink.click()

      URL.revokeObjectURL(url)
    } catch (err) {
      throwFeedbackError({ err })
    } finally {
      setIsDownloadingAdminLoaFile(false)
    }
  }

  const handleDeleteAdminLoa = async () => {
    if (isDeletingLoaFile) return
    setIsDeletingLoaFile(true)

    try {
      const { error: hhdDeleteError } = await fetchData({
        method: 'DELETE',
        url: `/core/private/consumer-meter/admin/admin-loa?${parseQuery({
          meterId,
        })}`,
      })

      if (hhdDeleteError) throw hhdDeleteError

      feedbackMessage(
        {
          title: 'Loa file deleted successfully',
        },
        'success',
      )
      handleFetchAdminLoaFile()
    } catch (err: any) {
      throwFeedbackError({ err })
    } finally {
      setIsDeletingLoaFile(false)
    }
  }

  const handleLoaUpload = () => {
    handleFetchAdminLoaFile()
    setIsLoaDrawerOpen(false)
  }

  const handleFetchLoaFile = async () => {
    if (isLoadingLoaFiles) return
    setIsLoadingLoaFiles(true)

    try {
      const { response, error: fetchError } = await fetchData({
        method: 'GET',
        url: `/core/private/consumer/admin/loa?${parseQuery({
          consumerId: meterDetails?.consumerId,
          companyId: meterDetails?.company.id,
        })}`,
        responseType: 'json',
      })

      if (fetchError) throw fetchError

      const base64Data = response?.data.loaFile
      const fileNameFromResponse = response?.data.fileName || 'loaFile.pdf'

      if (!base64Data) {
        setFetchedLoaFile(null)
        return
      }

      const binaryResponse = await fetch(
        `data:application/octet-stream;base64,${base64Data}`,
      )

      const binaryData = await binaryResponse.blob()

      const blob = new Blob([binaryData], {
        type: 'application/octet-stream',
      })

      setFetchedLoaFile({ fileName: fileNameFromResponse, blob })
    } catch (err: any) {
      throwFeedbackError(err)
    } finally {
      setIsLoadingLoaFiles(false)
    }
  }

  const handleDownloadLoa = async (file: any) => {
    if (isDownloadingLoaFile) return

    try {
      const { response, error: downloadError } = await fetchData({
        method: 'GET',
        url: `/core/private/consumer/admin/loa?${parseQuery({
          companyId: meterDetails?.company.id,
          consumerId: meterDetails?.consumerId,
        })}`,
        responseType: 'json',
      })

      if (downloadError) throw downloadError

      const base64Data = response?.data.loaFile

      const binaryResponse = await fetch(
        `data:application/octet-stream;base64,${base64Data}`,
      )
      const binaryData = await binaryResponse.blob()

      const blob = new Blob([binaryData], {
        type: 'application/octet-stream',
      })

      const url = URL.createObjectURL(blob)

      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.setAttribute('download', file.name)
      downloadLink.target = '_blank'
      downloadLink.click()

      URL.revokeObjectURL(url)
    } catch (err) {
      throwFeedbackError({ err })
    } finally {
      setIsDownloadingLoaFile(false)
    }
  }

  const handleFetchContractFiles = async () => {
    if (isLoadingContractFiles) return
    setIsLoadingContractFiles(true)

    try {
      const { response, error: fetchError } = await fetchData({
        method: 'GET',
        url: `/core/private/consumer-meter/admin/contract/list?${parseQuery({
          meterId,
          consumerId: meterDetails?.consumerId,
        })}`,

        responseType: 'json',
      })

      if (fetchError) throw fetchError

      const billFiles = response?.data.contractFiles || []

      const processedFiles = await Promise.all(
        billFiles.map(
          async (fileObject: { file: string; fileName: string }) => {
            const base64Data = fileObject.file || ''
            const fileNameFromResponse =
              fileObject.fileName || 'defaultFileName.pdf'

            const binaryResponse = await fetch(
              `data:application/octet-stream;base64,${base64Data}`,
            )
            const binaryData = await binaryResponse.blob()

            const blob = new Blob([binaryData], {
              type: 'application/octet-stream',
            })

            return { blob, fileName: fileNameFromResponse }
          },
        ),
      )

      setFetchedContractFiles(processedFiles)
    } catch (err: any) {
      throwFeedbackError(err)
    } finally {
      setIsLoadingContractFiles(false)
    }
  }

  const handleDownloadContract = async (file: any) => {
    if (isDownloadingContractFile) return

    try {
      const { response, error: downloadError } = await fetchData({
        method: 'GET',
        url: `/core/private/consumer-meter/admin/contract?${parseQuery({
          meterId,
          fileName: file.blob.fileName,
        })}`,
        responseType: 'json',
      })

      if (downloadError) throw downloadError

      const base64Data = response?.data.contractFile

      const binaryResponse = await fetch(
        `data:application/octet-stream;base64,${base64Data}`,
      )
      const binaryData = await binaryResponse.blob()

      const blob = new Blob([binaryData], {
        type: 'application/octet-stream',
      })

      const url = URL.createObjectURL(blob)

      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.setAttribute('download', file.blob.fileName)
      downloadLink.target = '_blank'
      downloadLink.click()

      URL.revokeObjectURL(url)
    } catch (err) {
      throwFeedbackError({ err })
    } finally {
      setIsDownloadingContractFile(false)
    }
  }

  useEffect(() => {
    if (!meterId) navigate('/meters')
  }, [meterId])

  useEffect(() => {
    if (error) throwFeedbackError({ err: error })
  }, [error])

  useEffect(() => {
    if (meterId && meterDetails?.consumerId) {
      handleFetchBillFiles()
    }
  }, [meterId, meterDetails?.consumerId])

  useEffect(() => {
    if (meterDetails && meterDetails.company.id) {
      handleFetchLoaFile()
    }
  }, [meterDetails])

  useEffect(() => {
    if (meterDetails && meterId) {
      handleFetchAdminLoaFile()
    }
  }, [meterDetails])

  useEffect(() => {
    if (meterId && meterDetails?.consumerId) {
      handleFetchContractFiles()
    }
  }, [meterId, meterDetails?.consumerId])

  useEffect(() => {
    handleFetchHhdFiles()
  }, [])

  return (
    <section
      className={classNames(
        container,
        breakPointsContainer,
        'items-center pt-12 relative',
      )}
    >
      <div className="absolute top-0 left-0 -mx-3 hover:brightness-50 cursor-pointer">
        <Button
          variant="text"
          icon={<ChevronLeftIcon />}
          onClick={() => navigate('/meters')}
        >
          {t('backButton')}
        </Button>
      </div>
      {isLoading && (
        <div className={classNames(transition, 'w-full fade-in')}>
          <Spinner className="mx-auto animate-spin w-5 h-5" />
        </div>
      )}
      {!isLoading && error && (
        <div className={classNames(transition, 'flex flex-col fade-in')}>
          <strong>{t('error.title')}</strong>

          <Button variant="primary" onClick={() => refetch()}>
            {t('error.button')}
          </Button>
        </div>
      )}
      {!isLoading && !error && meterDetails && (
        <>
          <div className="flex items-center justify-between w-full">
            <h1 className="text-3xl font-semibold text-ppa/title mt-3 mb-8 mr-8 w-full">
              {meterDetails.name}
            </h1>
          </div>

          <div className="flex flex-col justify-start items-start w-full gap-y-8">
            <div className="flex flex-col justify-start items-start w-full gap-y-2">
              <div className="flex justify-center items-center gap-x-8 max-w-[500px]">
                <h2 className="text-base font-medium text-ppa/title">
                  {t('consumerListTitle')}
                </h2>
                <Button
                  variant="primary"
                  icon={<ActAsIcon />}
                  onClick={handleActAs}
                  smallLabel
                >
                  {t('actAsButton')}
                </Button>
              </div>

              <div>
                <DefList rowKeys={rowKeysConsumer} data={consumerDetails} />
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-start items-start w-full gap-y-8 mt-8">
            <div className="flex flex-col justify-start items-start w-full gap-y-2">
              <div className="flex justify-center items-center gap-x-7 max-w-[500px]">
                <h2 className="text-base font-medium text-ppa/title">
                  {t('meterListTitle')}
                </h2>
                <Button
                  variant="primary"
                  icon={<BrokerFeeIcon />}
                  onClick={() => setIsEditDrawerOpen(true)}
                  smallLabel
                >
                  {meterDetails.brokerFee
                    ? t('actionsDropdown.updateBrokerFee')
                    : t('actionsDropdown.setBrokerFee')}
                </Button>
              </div>

              <div>
                <DefList rowKeys={rowKeys} data={meterDetails} />
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full">
            <div className="flex items-end gap-x-2 mt-10 mb-5">
              <h1 className="text-lg font-medium text-ppa/title mr-3">
                {t('loa.adminsListTitle')}
              </h1>
              <Button
                onClick={() => setIsLoaDrawerOpen(true)}
                variant="primary"
                icon={<UploadIcon />}
                smallLabel
              >
                {t('loa.uploadButton')}
              </Button>
            </div>

            <SlidingDrawer
              isOpen={isLoaDrawerOpen}
              handleClose={() => setIsLoaDrawerOpen(false)}
            >
              <UploadLoa
                meterId={meterId}
                isDrawerOpen={isLoaDrawerOpen}
                onSuccess={handleLoaUpload}
                onCancel={() => setIsLoaDrawerOpen(false)}
              />
            </SlidingDrawer>

            <CollapseList
              title={t('loa.collapseList.title')}
              titleEmptyList={t('loa.collapseList.filesNotFound')}
              list={
                fetchedAdminLoaFile
                  ? [
                      {
                        id: `loaFile-${meterId}`,
                        name: fetchedAdminLoaFile.fileName,
                        blob: fetchedAdminLoaFile.blob,
                      },
                    ]
                  : []
              }
              defaultOpen
              rowKeys={[
                { keyName: 'name' },
                {
                  keyName: 'actions',
                  containerClass: 'ml-auto pl-2 max-w-[50px]',
                  renderCustomEl: (file: any) => {
                    return (
                      <div className="flex items-center gap-x-2">
                        <DownloadIcon
                          className="w-5 h-5 cursor-pointer hover:brightness-75 stroke-ppa/primary"
                          onClick={() => handleDownloadAdminLoa(file)}
                        />
                        <TrashIcon
                          className="w-5 h-5 cursor-pointer hover:brightness-75 stroke-ppa/primary"
                          onClick={() => handleDeleteAdminLoa()}
                        />
                      </div>
                    )
                  },
                },
              ]}
            />

            <div className="flex items-end mb-5 mt-10">
              <h1 className="text-lg font-medium text-ppa/title mr-3">
                {t('loa.usersListTitle')}
              </h1>
            </div>

            <CollapseList
              title={t('loa.collapseList.title')}
              titleEmptyList={t('loa.collapseList.filesNotFound')}
              list={
                fetchedLoaFile
                  ? [
                      {
                        id: `blob-${meterId}`,
                        name: fetchedLoaFile.fileName,
                        blob: fetchedLoaFile.blob,
                      },
                    ]
                  : []
              }
              defaultOpen
              rowKeys={[
                { keyName: 'name' },
                {
                  keyName: 'actions',
                  containerClass: 'ml-auto pl-2 max-w-[50px]',
                  renderCustomEl: (file: any) => {
                    return (
                      <div className="flex items-center gap-x-2">
                        <DownloadIcon
                          className="w-5 h-5 cursor-pointer hover:brightness-75 stroke-ppa/primary"
                          onClick={() => handleDownloadLoa(file)}
                        />
                      </div>
                    )
                  },
                },
              ]}
            />
          </div>

          <div className="flex flex-col w-full">
            <div className="flex items-end mb-5 mt-10">
              <h1 className="text-lg font-medium text-ppa/title mr-3">
                {t('hhd.title')}
              </h1>
            </div>

            <CollapseList
              title={t('hhd.collapseList.title')}
              titleEmptyList={t('hhd.collapseList.filesNotFound')}
              list={
                hhdFiles && hhdFiles.length > 0
                  ? hhdFiles.map((file, index) => ({
                      id: `hhdFile-${index}`,
                      name: file.fileName,
                      blob: file,
                    }))
                  : []
              }
              defaultOpen
              rowKeys={[
                { keyName: 'name' },
                {
                  keyName: 'actions',
                  containerClass: 'ml-auto pl-2 max-w-[50px]',
                  renderCustomEl: (file: any) => {
                    return (
                      <div className="flex items-center gap-x-2">
                        <DownloadIcon
                          className={classNames(
                            'w-5 h-5',
                            'stroke-ppa/primary hover:brightness-75 cursor-pointer ',
                          )}
                          onClick={() => handleDownloadHHD(file)}
                        />
                      </div>
                    )
                  },
                },
              ]}
            />

            <div className="my-5">
              <DownloadHhd
                mpan={meterDetails?.mpan as string}
                adminHasLoa={fetchedAdminLoaFile !== null}
              />
            </div>
          </div>

          <div className="flex flex-col w-full">
            <div className="flex items-end mb-5 mt-10">
              <h1 className="text-lg font-medium text-ppa/title mr-3">
                {t('bill.title')}
              </h1>
            </div>

            <CollapseList
              title={t('bill.collapseList.title')}
              titleEmptyList={t('bill.collapseList.filesNotFound')}
              list={
                fetchedBillFiles && fetchedBillFiles.length > 0
                  ? fetchedBillFiles.map((file, index) => ({
                      id: `billFile-${index}`,
                      name: file.fileName,
                      blob: file,
                    }))
                  : []
              }
              defaultOpen
              rowKeys={[
                { keyName: 'name' },
                {
                  keyName: 'actions',
                  containerClass: 'ml-auto pl-2 max-w-[50px]',
                  renderCustomEl: (file: any) => {
                    return (
                      <div className="flex items-center gap-x-2">
                        <DownloadIcon
                          className="w-5 h-5 cursor-pointer hover:brightness-75 stroke-ppa/primary"
                          onClick={() => handleDownloadBill(file)}
                        />
                      </div>
                    )
                  },
                },
              ]}
            />
          </div>
          <div className="flex flex-col w-full">
            <div className="flex items-end mb-5 mt-10">
              <h1 className="text-lg font-medium text-ppa/title mr-3">
                {t('contract.title')}
              </h1>
            </div>

            <CollapseList
              title={t('contract.collapseList.title')}
              titleEmptyList={t('contract.collapseList.filesNotFound')}
              list={
                fetchedContractFiles && fetchedContractFiles.length > 0
                  ? fetchedContractFiles.map((file, index) => ({
                      id: `contractFile-${index}`,
                      name: file.fileName,
                      blob: file,
                    }))
                  : []
              }
              defaultOpen
              rowKeys={[
                { keyName: 'name' },
                {
                  keyName: 'actions',
                  containerClass: 'ml-auto pl-2 max-w-[50px]',
                  renderCustomEl: (file: any) => {
                    return (
                      <div className="flex items-center gap-x-2">
                        <DownloadIcon
                          className="w-5 h-5 cursor-pointer hover:brightness-75 stroke-ppa/primary"
                          onClick={() => handleDownloadContract(file)}
                        />
                      </div>
                    )
                  },
                },
              ]}
            />
          </div>

          <UpdateBrokerFeeDrawer
            meterId={meterDetails.id}
            brokerFee={meterDetails.brokerFee}
            isOpen={isEditDrawerOpen}
            onSuccess={refetch}
            handleClose={() => setIsEditDrawerOpen(false)}
          />
        </>
      )}
    </section>
  )
}

export default MeterDetails
